import React, {useContext, useEffect} from 'react';
import { useIdleTimer } from "react-idle-timer";
import {DataContext} from "../index";
import { useNavigate } from "react-router-dom";
import {resetALlHandler} from "../state/actions";
import {PROMO_SECONDS_OF_IDLENESS} from "../settings";

const IdleWrapper = (props) => {
    // useEffect(() => {
    //     const handleContextmenu = e => {
    //         e.preventDefault()
    //     }
    //     document.addEventListener('contextmenu', handleContextmenu)
    //     return function cleanup() {
    //         document.removeEventListener('contextmenu', handleContextmenu)
    //     }
    // }, [ ])

    const {state, dispatch} = useContext(DataContext);
    const navigate = useNavigate();

    const onIdle = () => {
        if(props.handleOnIdle) {
            props.handleOnIdle()
        }
    };

    const onActive = (event) => {
        idleTimer.reset();
    };

    const idleTimer = useIdleTimer({
        onIdle,
        onActive,
        timeout: 1000 * PROMO_SECONDS_OF_IDLENESS,
    });

    return (
       <div>
           {props.children}
       </div>
    );
};

export default IdleWrapper;
