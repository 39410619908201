import React, { useEffect, useState } from "react";

import QRBlock from "./QRBlock";
import ImageBlock from "./imageBlock";
import SpinnerBlock from "./spinnerBlock";
import { Trans } from "react-i18next";
import i18n from "i18next";


export default function DetailBlocks(
  listUrl,
  spinnerUrl,
  imagelist,
  count,
  colorScheme,
  maximizeImages,
  maximizeSpinner,
  image_qr_ordered_randomized,
  grid_order_randomized
) {
  const qrLang = i18n.language;
  const qrUrl = listUrl ? JSON.parse(listUrl)[qrLang] : ""; //TODO dynamic based on language

  return (
    <div className="relative h-80">
      <div
        id="detailBlocksBox"
        class="grid grid-cols-2 auto-cols-auto gap-[12px] w-4/5 max-w-fit flex-grow px-20 absolute top-0 left-0"
        style={{
          gridTemplateRows: "repeat(6, 4vh)",
        }}
      >
        {/* it sometimes happens that a car has no spinnerUrl */}
        {spinnerUrl && (
          <div
            id="spinnerBlockBox"
            className={`rounded row-span-5 row-start-2 row-end-6  ${grid_order_randomized[0]} drop-shadow-md animate__faster`}
            onClick={maximizeSpinner}
          >
            {SpinnerBlock(spinnerUrl)}
          </div>
        )}

        <div
          class={`row-span-full gap-[12px] ${grid_order_randomized[1]} flex-space-between flex-2 grid grid-rows-2 grid-cols-5 animate__animated animate__zoomIn animate__fast`}
        >
          <div
            id="imagesBlockBox"
            className={`animate__faster flex flex-row h-full row-start-1 col-span-3 ${image_qr_ordered_randomized[0]}`}
            onClick={maximizeImages}
          >
            {Array.isArray(imagelist) &&
              imagelist.length && //TODO, better work with cars that have no/little images
              ImageBlock(
                imagelist[1] ? imagelist[1]["url"] : imagelist[0]["url"],
                imagelist[2] ? imagelist[2]["url"] : imagelist[0]["url"],
                imagelist[3] ? imagelist[3]["url"] : imagelist[0]["url"]
              )}
          </div>
          <div
            id="qrBlockBox"
            style={{ backgroundColor: colorScheme.qrBlockBgColor }}
            className={`rounded flex-1 space-between items-center row-start-2 col-span-4 ${image_qr_ordered_randomized[1]} p-4 flex flex-row drop-shadow-md animate__faster`}
          >
            {QRBlock(qrUrl, colorScheme.qrColor)}
            <div class="flex flex-col ml-4 text-2xl font-semibold">
              <Trans i18nKey="qrText" count={count}>
                <span style={{ color: colorScheme.qrTextColor }}>
                  Découvrez
                </span>
                <span style={{ color: colorScheme.qrColor }}>
                  les {{ count }} véhicules
                </span>
                <span style={{ color: colorScheme.qrTextColor }}>
                  disponibles
                </span>
              </Trans>
            </div>
          </div>
        </div>
      </div>
      <div
        id="detailBlocksBoxPlaceholder"
        class="grid grid-cols-2 auto-cols-auto gap-[12px] w-4/5 max-w-fit flex-grow px-20 invisible absolute top-0 left-0"
        style={{
          gridTemplateRows: "repeat(6, 4vh)",
        }}
      >
        <div id="qrBlockBox"></div>
      </div>
    </div>
  );
}
