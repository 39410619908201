import React from 'react';
import {map, find} from 'lodash';
import {useTranslation} from 'react-i18next';
import Question from "./Question";

const QuestionsMakes = ({makesQuestions, currentAnswers, language, onClick, hasOverflow}) => {
    const {t} = useTranslation();
    return (
        <div className={`grid grid-cols-3 gap-4 w-full ${hasOverflow ? 'h-full' : ''} `}>
            <div className="col-span-3 text-2xl text-gray-darker mb-6">{t('Choose maximum 2 makes.')}</div>

            {map(makesQuestions, (answer, i) => {
                const selected = find(currentAnswers, (a) => a.title[language] === answer.title[language])
                return <Question title={answer.title[language]}
                                 isSelected={selected}
                                 totalResults={answer.resultCount}
                                 onClick={() => onClick(answer)}/>
            })}

        </div>
    );
};

export default QuestionsMakes;
