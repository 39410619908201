import React, {useContext, useEffect, useReducer} from 'react';
import {map, omitBy, keys, sumBy, times} from 'lodash';
import {dataReducer, initialState} from "./reducer";
import {
    clearHandler,
    fetchingSetHandler,
    initializeHandler,
    nextQuestionHandler,
    resultsSetHandler, shortUrlSetHandler, startScreenVisibleSetHandler
} from "./actions";
import {useMutation} from "graphql-hooks";
import CompletedScreen from "./general/CompletedScreen";
import StartScreen from "./general/StartScreen";
import Header from "./general/Header";
import Questions from "./general/Questions";
import { v4 } from "uuid";
import {DataContext} from "../../index";
import {generateTemptTokenWithTime} from "./utils";



const VEHICLES_SEARCH_QUERY = `query VehicleSearch($first: Int, $makeModelFamily: String, $fuels: String, $labels: String, $bodyTypes: String, $priceMin: Decimal, $priceMax: Decimal, $seatsMin: Decimal, $seatsMax: Decimal, $co2Min: Decimal, $co2Max: Decimal, $hpMin: Decimal, $hpMax: Decimal) {
     vehicleSearch(first: $first, makeModelFamily: $makeModelFamily, fuels: $fuels, labels: $labels, bodyTypes: $bodyTypes, priceMin: $priceMin, priceMax: $priceMax, seatsMin: $seatsMin, seatsMax: $seatsMax, co2Min: $co2Min, co2Max: $co2Max, hpMin: $hpMin, hpMax: $hpMax)  {
        totalCount
        filters {
            url
            makes {
                key
                description
                count
                priority
                models {
                    key
                    description
                    count
                    priority
                }
            }
            fuels {
                key
                description
                priority
                count
            }
            bodyTypes {
                key
                description
                priority
                count
            }
           labels {
                key
                description
                count
            }
            seats {
                key
                value
                count
            }
            co2 {
                key
                value
                count
            }
            hp {
                key
                value
                count
            }
        }
        edges {
            node {
                noBackgroundImageUrl
                make
                makeKey
                model  
                modelKey    
                modelFamilyKey         
            }
        }      
    }
}`;

const SHORTEN_URL_MUTATION = `
    mutation ShortenUrl($data: ShortenUrlInputType!) {
        shortenUrl(data: $data)  {
           ok      
           url   
           error 
        }
    }`;

const SEND_DATA_MUTATION = `
    mutation saveCampaignData($data: CampaignDataType!, $context: ContextDataType!) {
        sendHelpMeChooseData(data: $data, context: $context)  {
           ok
        }
    }`;


const HelpMeChoose = (props) => {
    const {data, dispatch} = props;
    const {state} = useContext(DataContext);
    const [shortenUrl] = useMutation(SHORTEN_URL_MUTATION)
    const [sendData] = useMutation(SEND_DATA_MUTATION)

    useEffect(() => {
        dispatch(initializeHandler())
    }, []);

    useEffect(() => {
        if (data.filtersLastUpdated !== undefined) {
            queryData(data.filters)
        }
    }, [data.filtersLastUpdated]);

    useEffect(() => {
        console.log(data);
        console.log(data.result.origUrl);
        if (data.completed) {

            // 1. Shorten url
            shortenUrl({
                variables: {
                    data: {
                        'url': data.result.url
                    }
                },
                fetchOptionsOverrides: {
                    headers: {
                        "Authorization": generateTemptTokenWithTime()
                    }
                },
                onSuccess(result, variables) {
                    const shortUrl = result.data.shortenUrl.url



                    dispatch(shortUrlSetHandler(shortUrl))

                    sendData({
                      variables: {
                        data: {
                          reference: data.uniqueId,
                          language: state.language,
                          answers: JSON.stringify(data.allAnswers),
                          filters: JSON.stringify(data.filters),
                          url: data.result.origUrl,
                          shortUrl: shortUrl,
                        },
                        context: {
                          transactionId: v4(),
                          transactionTenant: "BMS",
                          transactionType: "saveCampaignData"
                        },
                      },
                    });
                }
            });

            // 2. Call to crm with filterData
        }
    }, [data.completed]);


    const queryData = (filters) => {
        // remove all undefined filters
        let variables = omitBy(filters, v => v === undefined)

        if (keys(variables).length === 0)
            return

        dispatch(fetchingSetHandler(true))

        // To speed up the results query
        const totalNext = sumBy(data.currentAnswers, 'resultCount')
    
        if ((totalNext && totalNext > 100 && data.currentQuestion.number !== data.questions.length) || data.currentQuestion.number === 1 || (totalNext === undefined && data.currentQuestion.number !== data.questions.length)) {
            variables['first'] = 0
        }

        fetch('/graphql', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Accept-Language': state.language
            }, body: JSON.stringify({query: VEHICLES_SEARCH_QUERY, variables: variables})
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.data && res.data.vehicleSearch) {

                    const count = res.data.vehicleSearch.totalCount
                    const url = res.data.vehicleSearch.filters.url
                    const data = {
                        makes: res.data.vehicleSearch.filters.makes,
                        fuels: res.data.vehicleSearch.filters.fuels,
                        bodyTypes: res.data.vehicleSearch.filters.bodyTypes,
                        labels: res.data.vehicleSearch.filters.labels,
                        seats: res.data.vehicleSearch.filters.seats,
                        co2: res.data.vehicleSearch.filters.co2,
                        hp: res.data.vehicleSearch.filters.hp,
                    }

                    const vehicles = (res.data.vehicleSearch.edges.length <= 100) ? map(res.data.vehicleSearch.edges, (edge) => edge.node) : []

                    dispatch(resultsSetHandler(count, url, data, vehicles))
                    dispatch(nextQuestionHandler());
                    dispatch(fetchingSetHandler(false))

                }
            })
            .catch((error) => {
                dispatch(fetchingSetHandler(false))
            });
    }

    if (data.loading) {
        return <div className="w-full h-screen py-[104px]">
            <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="h-[120px] w-1/2 animation-pulse bg-gray-200"/>

                <div className="flex flex-col items-center justify-center mt-7 text-center mb-14">
                    <div className="w-96 h-8 animation-pulse bg-gray-200 mb-2"/>
                    <div className="w-80 h-8 animation-pulse bg-gray-200 "/>
                </div>

                <div className="flex space-x-10 mb-[88px]">
                    {times(3, item => {
                        return <div key={`completed-skeleton-${item}`} className="animation-pulse bg-gray-200 rounded-[16px] min-w-[360px] w-[360px] h-[390px]" />
                    })}

                </div>

                <div className="flex items-center justify-center">
                    <div className="w-[136px] h-[136px] mr-14 animation-pulse bg-gray-200"/>
                    <div className="flex flex-col">
                        <div className="h-8 animation-pulse bg-gray-200 w-80 mb-4"/>
                        <div className="h-4 animation-pulse bg-gray-200 w-96 mb-1"/>
                        <div className="h-4 animation-pulse bg-gray-200 w-72"/>
                    </div>
                </div>
            </div>
        </div>
    }

    if(data.startScreenVisible){
        return <div className="relative">
            <Header isStartScreen={true}/>
            <StartScreen onStart={() => dispatch(startScreenVisibleSetHandler(false))} />
        </div>
    }

    if(data.completed){
        return <div className="relative">

            <Header isStartScreen={false}
                    hideLanguagePicker={true}
                    onStartAgain={() => dispatch(clearHandler())}/>

            <CompletedScreen result={data.result}/>
        </div>
    }

    return <div className="flex h-screen">
        <Header isStartScreen={false}
                onStartAgain={() => dispatch(clearHandler())}/>

        <Questions data={data}
                   dispatch={dispatch}/>
    </div>
};

export default HelpMeChoose;
