import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darkBlueGradient } from "../../settings";

export default function ImageBlock(pic1, pic2, pic3) {
  return (
    <div
      class="relative grid grid-rows-2 grid-cols-3 gap-[12px] flex-1 overflow-hidden drop-shadow-md"
    >
      <img
        class="h-full w-full  object-cover row-start-1 col-start-1 rounded "
        src={pic1}
        alt="carview 1"
      />
      <img
        class="h-full w-full  object-cover row-start-2 col-start-1 rounded drop-shadow-md"
        src={pic2}
        alt="carview 2"
      />
      <img
        class="h-full w-full object-cover col-span-2  col-start-2 row-span-2 rounded drop-shadow-md"
        src={pic3}
        alt="carview 3"
      />
      {/* adjust -2rem if you change size of icon '4x' */}

      <div class="absolute z-10 " style={{ right: "-2rem", bottom: "-2rem" }}>
        <FontAwesomeIcon
          class="absolute z-20"
          icon={["far", "search-plus"]}
          style={{ height: "0.8rem", top: "0.9rem", left: "0.9rem" }}
          color={darkBlueGradient.left}
        />
        <FontAwesomeIcon
          icon="circle-quarter"
          size="4x"
          color="rgba(255, 255, 255, 0.95)"
        />
      </div>
    </div>
  );
}
