import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import enTranslation from "./translations/en/en.json";
import nlTranslation from "./translations/nl/nl.json";
import frTranslation from "./translations/fr/fr.json";
import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE} from "./settings";

const resources = {
    en: {
        translation: enTranslation,
    },
    nl: {
        translation: nlTranslation,
    },
    fr: {
        translation: frTranslation,
    },
};

i18n
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        lng: DEFAULT_LANGUAGE,
        fallbackLng: DEFAULT_LANGUAGE,
        supportedLngs: AVAILABLE_LANGUAGES,
        debug: true,
        returnEmptyString: false,
    });

export default i18n;
