import React from "react";
import {find} from "lodash";
import logoSalonDeals from "../../../../assets/MyWay-SalonDeals-2023.png";
import {formatNumber} from "../../../../utils";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";

const VehicleCard = (props) => {
    const {vehicle} = props;
    const { t } = useTranslation();
    const currentLocation = window.location.href;
    const vehicleLocation = currentLocation + "/" + vehicle.reference;

    // TODO TRANSLATE
    //
    const salon_labels = [
        {key: "salon_deals_fuel_or_electric_750", label: "+1 fuel card of € 750"},
        {key: "salon_deals_fuel_or_electric_500", label: "+1 fuel card of € 500"},
        {key: "salon_deals_fuel_or_electric_250", label: "+1 fuel card of € 250"},
        {key: "salon_deals_warranty_+4y", label: "+4 years warranty"},
        {key: "salon_deals_warranty_+3y", label: "+3 years warranty"},
        {key: "salon_deals_warranty_+2y", label: "+2 years warranty"},
        {key: "salon_deals_warranty_+1y", label: "+1 year warranty"},
    ];

    const print_label = (label_key) => {
        if(!label_key || label_key === undefined)
            return

        const salon_promo = find(salon_labels, (label) => label.key === label_key);
        return (
            <div className="font-semibold text-base text-blue">
                {salon_promo.label}
                <sup>*</sup>
            </div>
        );
    };

    let detailUrl = `/promo/detail/${vehicle.reference}`
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tmpParams = urlSearchParams.toString()
    detailUrl = (tmpParams !== "") ? `${detailUrl}?${tmpParams}` : detailUrl


    return (
      <Link to={detailUrl}>
        <div className="w-full relative mb-4" style={{ aspectRatio: "4/3" }}>
          <div
            className="w-full absolute top-0 left-0 object-cover object-center overflow-hidden"
            style={{ aspectRatio: "4/3", zIndex: 10 }}
          >
            <img src={vehicle.thumbnailUrl} style={{ width: 547 }} />
          </div>
          <div
            className="w-full bg-gray-lighter animate-pulse rounded-4 absolute top-0 left-0"
            style={{ aspectRatio: "4/3", zIndex: 0 }}
          ></div>
        </div>
        <div className="font-semibold text-base mb-1 truncate">
          {vehicle.make} {vehicle.model}
        </div>
        <div className="font-regular text-xs text-gray-dark mb-3 truncate">
          {vehicle.version}
        </div>
        <div className="font-semibold text-xl flex flex-row items-start mb-3">
          <span className="text-xs mt-0.5 mr-1">€</span>{" "}
          <span className="mr-1">{formatNumber(vehicle.price)}</span>{" "}
          <span className="text-xs mt-0.5">{t("BTWi")}</span>
        </div>
        {/* <div className="myway-mesh p-1 mb-3">
                <div className="bg-white p-3 flex flex-row flex-nowrap justify-between items-center">
                    {print_label(vehicle.salonDeal?.key)}
                    <div className="ml-1 w-16 object-contain">
                        <img
                            className="cursor-hand"
                            src={logoSalonDeals}
                            alt="Salon Deals - MyWay - 2023"
                        />
                    </div>
                </div>
            </div> */}
        <div className="flex flex-row flex-nowrap mb-2">
          {vehicle.firstRegistrationYear && (
            <div className="bg-gray-lighter text-xs px-2 py-1 mr-2 rounded">
              {vehicle.firstRegistrationYear}
            </div>
          )}
          {vehicle.mileage && (
            <div className="bg-gray-lighter text-xs px-2 py-1 mr-2 rounded">
              {vehicle.mileage} km
            </div>
          )}
          {vehicle.gearbox && (
            <div className="bg-gray-lighter text-xs px-2 py-1 rounded">
              {vehicle.gearbox}
            </div>
          )}
        </div>
        <div className="flex flex-row flex-nowrap">
          {vehicle.fuel && (
            <div className="bg-gray-lighter text-xs px-2 py-1 mr-2 rounded">
              {vehicle.fuel}
            </div>
          )}
          {(vehicle.co2 || vehicle.co2 === 0) && (
            <div className="bg-gray-lighter text-xs px-2 py-1 rounded">
              {vehicle.co2} g/km CO2
            </div>
          )}
        </div>
      </Link>
    );
};

export default VehicleCard;
