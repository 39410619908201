import React, {useContext, useEffect, useState} from 'react';
import {evaluateAnswers} from "../utils";
import {find, map, times, last} from 'lodash';
import {answerSetHandler, clearHandler, filtersUpdateHandler, nextQuestionHandler, previousQuestionHandler} from "../actions";
import StepIndicator from "./StepIndicator";
import {useTranslation} from 'react-i18next';
import {animateCSS} from "../../../utils";
import {DataContext} from "../../../index";
import QuestionsMakes from "./QuestionsMakes";
import Question from "./Question";

const animationBase = 'animate__animated'; // This is required
const animationDuration = 'animate__faster';
const animationIn = 'animate__fadeInUp';
const animationOut = 'animate__fadeOutUp';

const Questions = ({data, dispatch}) => {
    const {currentQuestion, questions, currentAnswers, allAnswers, fetching, result, filters, history} = data;
    const {state} = useContext(DataContext);
    const [hasOverflow, setHasOverflow] = useState(false);
    const {language} = state;
    const {t} = useTranslation();
    const lastAnswer = last(allAnswers)
    const lastTitle = lastAnswer ? lastAnswer?.question[language] : null;

    // Effect to center the questions content if there is no overflow and to add classes to show three row if it is makes question
    // Effect to add in animations on new question
    useEffect(() => {
        const questionsWrapper = document.getElementById('questions-wrapper');
        const titleWrapper = document.getElementById('title-wrapper');
        if (questionsWrapper) {
            if (questionsWrapper.scrollHeight > questionsWrapper.clientHeight) {
                // questionsWrapper.classList.remove('flex-wrap', 'justify-center');
                questionsWrapper.classList.add('flex-col', 'pb-4');
                setHasOverflow(true);
                // if (currentQuestion.type === 'make') {
                //     questionsWrapper.classList.remove('flex-col');
                //     questionsWrapper.classList.add('flex', 'flex-wrap')
                // }
            } else {
                questionsWrapper.classList.add('flex', 'justify-center', 'flex-col');
                setHasOverflow(false);
                // questionsWrapper.classList.remove('justify-center');
                // questionsWrapper.classList.add('flex', 'justify-start', 'flex-wrap');
                // if (currentQuestion.type !== 'make') {
                //     questionsWrapper.classList.add('justify-center', 'flex-col')
                // }
            }
        }
        // // ADD IN ANIMATIONS
        if (questionsWrapper) {
            questionsWrapper.classList.remove(animationOut);
            questionsWrapper.classList.add(animationIn)
        }

        if (titleWrapper) {
            titleWrapper.classList.remove(animationOut);
            titleWrapper.classList.add(animationIn)
        }
        // animateCSS('#questions-wrapper', 'fadeInUp')
        // animateCSS('#title-wrapper', 'fadeInUp')
    }, [currentQuestion]);

    // Effect to add OUT animations on new question
    useEffect(() => {
        const questionsWrapper = document.getElementById('questions-wrapper');
        const titleWrapper = document.getElementById('title-wrapper');
        // if(fetching){
        //     animateCSS('#questions-wrapper', 'fadeOutUp')
        //     animateCSS('#title-wrapper', 'fadeOutUp')
        // }else{
        //
        // }
        if (fetching) {
            // ADD OUT ANIMATIONS
            if (questionsWrapper) {
                questionsWrapper.classList.remove(animationIn);
                questionsWrapper.classList.add(animationOut);
            }
            if (titleWrapper) {
                titleWrapper.classList.remove(animationIn);
                titleWrapper.classList.add(animationOut);
            }
        }
    }, [fetching])


    const handleAnswerClick = (question, answer) => {
        dispatch(answerSetHandler(question, answer))
    }

    const skipQuestion = () => {
        dispatch(nextQuestionHandler());
    }

    if (currentQuestion === undefined) {
        return <div className="hidden"/>
    }

    const onBack = () => {
        dispatch(previousQuestionHandler());
        const questionsWrapper = document.getElementById('questions-wrapper');
        const titleWrapper = document.getElementById('title-wrapper');
        // setTimeout(() => {
        //     animateCSS('#questions-wrapper', 'fadeInUp');
        //     animateCSS('#title-wrapper', 'fadeInUp');
        // }, 100)
        if (questionsWrapper) {
            if (questionsWrapper.classList.contains(animationIn)) {
                questionsWrapper.classList.remove(animationIn);
            }
            setTimeout(() => questionsWrapper.classList.add(animationIn), 100)
        }
        //
        if (titleWrapper) {
            if (titleWrapper.classList.contains(animationIn)) {
                titleWrapper.classList.remove(animationIn);
            }
            setTimeout(() => titleWrapper.classList.add(animationIn), 100)
        }
    }

    const onNext = () => {
        dispatch(filtersUpdateHandler());
    }

    const renderQuestionsLoadingSkeleton = () => {
        return times(5, item => {
            const isLast = item === 4;
            return <div key={`question-skeleton-${item}`} className={`bg-gray-lighter animate-pulse w-full rounded-[12px] h-[80px] ${isLast ? '' : 'mb-4'}`}/>
        })
    }

    const isMakesQuestion = currentQuestion.type === 'make';

    return (
        <div className="flex h-full w-full">
            <div className="relative flex justify-start w-1/2 pb-[104px]" style={{width: "50%"}}>
                <div className="absolute inset-0 myway-mesh-vertical rotate-180"/>
                <div className="flex relative items-center justify-center w-full text-[60px] px-[165px] text-white text-center my-[84px]">
                    <div id="title-wrapper" className={`${animationBase} ${animationDuration} pt-[84px]`}>
                        {fetching && (lastTitle ? lastTitle : <div className="h-[60px] rounded-[12px] bg-gray-lighter animate-pulse w-full"/>)}
                        {!fetching && currentQuestion?.title[language]}
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-1/2 pt-[104px] h-full">
                <div id="questions-wrapper" className={`flex-1 px-[165px] max-h-full h-full overflow-y-scroll no-scrollbar gap-4 mb-4 ${animationBase} ${animationDuration}`}>
                    {fetching && renderQuestionsLoadingSkeleton()}

                    {!fetching && isMakesQuestion && <QuestionsMakes makesQuestions={evaluateAnswers(currentQuestion, result.data, filters, () => skipQuestion())}
                                                                     currentAnswers={data.currentAnswers}
                                                                     language={language}
                                                                     hasOverflow={hasOverflow}
                                                                     onClick={(answer) => handleAnswerClick(currentQuestion, answer)}/>}

                    {!fetching && !isMakesQuestion && map(evaluateAnswers(currentQuestion, result.data, filters, () => skipQuestion()), (answer) => {
                        const selected = find(data.currentAnswers, (a) => a.title[language] === answer.title[language])
                        return <Question key={answer.number}
                                         title={answer.title[language]}
                                         isSelected={selected}
                                         totalResults={answer.resultCount}
                                         onClick={() => handleAnswerClick(currentQuestion, answer)}/>
                    })}
                </div>
                <div className="flex flex-col z-[2] bg-white">

                    <StepIndicator currentStep={currentQuestion.number}
                                   totalSteps={questions.length}/>

                    <div className={`flex items-center py-4 px-12 ${history.length > 0 ? 'justify-between' : 'justify-end'}`}>
                        {history.length > 0 && <div className="underline text-darkText text-[15px] cursor-pointer"
                                                    onClick={() => onBack()}>
                            {t('Back')}
                        </div>}
                        <div
                            className={`btn-primary text-[15px] font-normal h-12 px-10 flex items-center justify-center hover:bg-opacity-80 transition rounded-lg transition ${currentAnswers.length === 0 ? 'pointer-events-none opacity-50' : 'cursor-pointer opacity-100'}`}
                            onClick={() => onNext()}>
                            {t('Next')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Questions;
