import React, { useEffect, useState, useContext } from "react";
import { useQuery } from "graphql-hooks";
import { useIdleTimer } from "react-idle-timer";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleQuarter,
  faHandPointer,
} from "@fortawesome/pro-solid-svg-icons";
import { fas, faHand, faRefresh } from "@fortawesome/free-solid-svg-icons";
import {
  far,
  faMagnifyingGlassPlus,
  faXmarkCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";

import MakeModelList from "../components/wall/makeModelList";
import CarDetail from "../components/wall/carDetail";
import {
  colorSchemes,
  seconds_of_idleness_for_scroll,
  seconds_of_idleness_for_hand,
  seconds_between_cars_for_scroll,
  LANGUAGES,
} from "../settings";
import LanguagePicker from "../components/wall/LanguagePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataContext } from "../index";
import { languageSetHandler } from "../state/actions";

library.add(
  fas,
  far,
  fal,
  faMagnifyingGlassPlus,
  faCircleQuarter,
  faXmarkCircle,
  faHand,
  faHandPointer,
  faRefresh
); //add the used fontAwesome icons here

const WALL_QUERY = `query MakeModelCount {
  makeModelCount {
    make
    model
    count
    minPrice
    images {
           key
            url
            priority
    }
    spinnerUrl
    noBackgroundImageUrl
    listUrl
  }
}`;

const Wall = () => {
  const { loading, error, data } = useQuery(WALL_QUERY);
  const [focusedCar, setfocusedCar] = useState({});
  const [selectedBoundingBox, setSelectedBoundingBox] = useState({});
  const [colorScheme, setColorScheme] = useState(colorSchemes[0]);
  const [nextCarSignal, setNextCarSignal] = useState();
  const [previousCarSignal, setPreviousCarSignal] = useState();
  const [isIdle, setisIdle] = useState(false);


  const { state, dispatch } = useContext(DataContext);

  //code for idleness
  const onIdle = () => {
    setisIdle(true);
  };

  const onActive = (event) => {
    idleTimer.reset();
    setisIdle(false);
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    timeout: 1000 * seconds_of_idleness_for_hand,
  });

  const possibleLanguages = Object.keys(LANGUAGES);
  const onIdleInterval = () => {
    const currentLangIndex = possibleLanguages.indexOf(state.language);
    const newLang =
      possibleLanguages[(currentLangIndex + 1) % possibleLanguages.length];

    setNextCarSignal(Math.random());
    dispatch(languageSetHandler(newLang));
  };

  useEffect(() => {
    let idleInterval;
    let timer1;
    if (isIdle) {
      //first let the hand icon move icon
      const secondsOfSleep =
        seconds_of_idleness_for_scroll - seconds_of_idleness_for_hand;

      // After a longer interval change cars automatically
      timer1 = setTimeout(() => {
        onIdleInterval(); //fire the first time immediatly
        idleInterval = setInterval(
          () => onIdleInterval(),
          1000 * seconds_between_cars_for_scroll
        );
      }, secondsOfSleep * 1000);
    } else {
      clearTimeout(timer1);
      clearInterval(idleInterval);
    }
    return () => {
      clearTimeout(timer1);
      clearInterval(idleInterval);
    };
  }, [isIdle]);

  //end code for idleness

  if (loading) return "Loading...";
  if (error) return <div>Something unexpected happen. Please contact an employee</div>;

  return (
    <div
      class="text-white px-4 flex flex-row flex-1 overflow-hidden"
      style={{ overscrollBehavior: "none" }}
    >
      {/* invisible button to refresh the app */}
      <div
        class="absolute right-5 top-5"
        onClick={() => window.location.reload(false)}
      >
        <FontAwesomeIcon
          size="2x"
          color="transparent"
          icon={["fas", "refresh"]}
        />
      </div>
      <MakeModelList
        cars={data.makeModelCount}
        onSelect={(data) => setfocusedCar(data)}
        getBoundingBoxSelected={(boundingBox) =>
          setSelectedBoundingBox(boundingBox)
        }
        setColorScheme={(gradient) => setColorScheme(gradient)}
        colorScheme={colorScheme}
        scrollToNextSignal={nextCarSignal} //very dirty way to get signal from nextbutton to list
        scrollToPreviousSignal={previousCarSignal}
        isIdle={isIdle}
      />
      <CarDetail
        car={focusedCar}
        boundingBox={selectedBoundingBox}
        colorScheme={colorScheme}
        onClickNext={() => setNextCarSignal(Math.random())} //very dirty way to get signal from nextbutton to list
        onClickPrevious={() => setPreviousCarSignal(Math.random())} //very dirty way to get signal from nextbutton to list
      />
      <LanguagePicker />
    </div>
  );
};

export default Wall;
