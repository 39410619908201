import React, {useContext, useState, useEffect} from "react";
import VehicleList from "../components/promo/vehicle/list/VehicleList";
import LanguagePicker from "../components/promo/general/LanguagePicker";
import logoSalonDeals from "../assets/MyWay-SalonDeals-2023.png";
import swipeHorizontal from "../assets/swipe-horizontal.svg";
import FiltersContainer from "../components/promo/vehicle/filters/FiltersContainer";
import IdleWrapper from "../common/IdleWrapper";
import {DEVELOPMENT_MODE, PROMO_SECONDS_OF_IDLENESS_FOR_HAND} from "../settings";
import { useNavigate } from "react-router-dom";
import {resetALlHandler} from "../state/actions";
import {DataContext} from "../index";
import { useIdleTimer } from "react-idle-timer";

const PromoList = (props) => {
    const {state, dispatch} = useContext(DataContext);
    const navigate = useNavigate();

    const [isIdle, setisIdle] = useState(false);
    const [showScrollIcon, setShowScrollIcon] = useState(false);

    //code for idleness
    const onIdle = () => {
        setisIdle(true);
        setShowScrollIcon(true);
    };

    const onActive = (event) => {
        idleTimer.reset();
        setisIdle(false);
        scrollIconFadeOut();
        setTimeout(() => {setShowScrollIcon(false)}, 500);
    };

    const onAction = () => {
        setisIdle(false);
        scrollIconFadeOut();
        setTimeout(() => {setShowScrollIcon(false)}, 500);
    }

    const idleTimer = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 1000 * PROMO_SECONDS_OF_IDLENESS_FOR_HAND
    });

    const scrollIconFadeOut = () => {
        const el = document.getElementById('scrollIconBox');
        if(el){
            el.classList.remove('animate__fadeInUp');
            el.classList.add('animate__fadeOutDown');
        }
    }

    useEffect(() => {
        setShowScrollIcon(true)
    }, [])
    //end code for idleness

    return (
        <IdleWrapper handleOnIdle={() => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            navigate({
                pathname: '/promo/list',
                search:  urlSearchParams.toString(),
            });
            dispatch(resetALlHandler())
        }}>
            <div className="h-screen overflow-hidden">
                <div className="grid grid-cols-3 items-center h-12.592vh px-3.125vw">
                    <div className="justify-self-start">
                        <LanguagePicker/>
                    </div>
                    <div className="justify-self-center">
                        <div className="w-12vw object-contain">
                            <img
                                className="cursor-hand"
                                src={logoSalonDeals}
                                alt="Salon Deals - MyWay - 2023"
                                onClick={() => {
                                    if(DEVELOPMENT_MODE){
                                        navigate('/')
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <FiltersContainer/>
                </div>
                <div className="pl-3.125vw pb-3.125vw overflow-x-auto overflow-y-hidden"
                    style={{height:"87.408vh"}}
                >
                    <VehicleList {...props}/>
                    {showScrollIcon && <div
                        id='scrollIconBox'
                        className='fixed bottom-1 left-[50vw] animate__animated animate__fadeInUp animate__faster'
                        style={{ marginLeft: '-2.5rem' }}
                        >
                        <img
                            class="animate__animated animate__headShake animate__delay-1s"
                            src={swipeHorizontal}
                            alt="swipe list"
                            style={{ height: "5rem", width: "auto" }}
                        />
                    </div>}
                </div>
            </div>
        </IdleWrapper>
    );
};

export default PromoList;
