import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Trans } from "react-i18next";

export default function CloseButton(onCloseClick) {

  return (
    <div
      class=" text-darkText fixed align-middle left-1/2 mt-6 flex flex-row"
      id="closeButton"
      onClick={onCloseClick}
    >
      <div class="mr-2">
        <FontAwesomeIcon size="xl" icon={["fal", "xmark-circle"]} />
      </div>

      <Trans i18nKey="close">Close</Trans>
    </div>
  );
}
