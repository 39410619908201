import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darkBlueGradient } from "../../settings";

export default function c(spinnerUrl) {
  // a lot of relative viewport heights and values to adjust for the iframe and the relative ratios of the screen. If there are problems play with these values:
  // h-[24vh]
  // mt-[-7vh]

  return (
    <div
      className="relative bg-blue-300 rounded  overflow-hidden animate__animated animate__zoomIn animate__faster"
      style={{ aspectRatio: 20 / 13 }}
    >
      <iframe
        class="rounded pointer-events-none relative mt-[-13.5vh]"
        src={spinnerUrl}
        height="190%"
        width="100%"
        id="spinner"
        title="spinner"
        scrolling="no"
        seamless
      />
      <div class="absolute z-10 " style={{ right: "-2rem", bottom: "-2rem" }}>
        <FontAwesomeIcon
          class="absolute z-20"
          icon={["far", "search-plus"]}
          style={{ height: "0.8rem", top: "0.9rem", left: "0.9rem" }}
          color={darkBlueGradient.left}
        />
        <FontAwesomeIcon
          icon="circle-quarter"
          size="4x"
          color="rgba(255, 255, 255, 0.95)"
        />
      </div>
    </div>
  );
}
