import React, { useContext, useRef, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import i18n from "i18next";
import DetailBlocks from "./detailBlocks";
import ImageViewer from "./imageViewer";
import CloseButton from "./closeButton";
import logoMyway from "../../assets/myway_logo.png";
import arc from "../../assets/arc.png";
import {DEVELOPMENT_MODE} from "../../settings";
import {useNavigate} from "react-router-dom";
import {animateCSS} from "../../utils";

import { DataContext } from "../../index";

// Anim classes
//
const animationBase = 'animate__animated'; // This is required

const animationDurationSlower = 'animate__slower';
const animationDurationSlow = 'animate__slow';
const animationDurationFast = 'animate__fast';
const animationDurationFaster = 'animate__faster';

const animationFadeInUp = 'animate__fadeInUp';
const animationFadeOutUp = 'animate__fadeOutUp';
const animationFadeInDown = 'animate__fadeInDown';
const animationFadeOutDown = 'animate__fadeOutDown';

const animFadeInUp = 'fadeInUp';
const animFadeInDown = 'fadeInDown';
const animFadeOutDown = 'fadeOutDown';
const animFlipOutX = 'flipOutX';


function sortArrayRandom() {
  return Math.random() - 0.5;
}

export default function CarDetail({
  car,
  boundingBox,
  colorScheme,
  onClickNext,
  onClickPrevious
}) {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState("default");
  const { minPrice, make, model, images, spinnerUrl, listUrl, count, noBackgroundImageUrl } = car;
  const image_uri = noBackgroundImageUrl;
    const navigate = useNavigate();

  const coloredDetailRef = useRef();

  const {state, dispatch} = useContext(DataContext);

  // Add base animate class
  //
  const setAnimateBase = (elId) => {
    const el = document.getElementById(elId);
    el?.classList.add(animationBase);
  }

  const handleTopImgAnimation = () => {
    // Fadeout
    animateCSS('#topImgBoxPlaceholder', animFadeOutDown).then((message) => {
      //
    });

    // FadeIn
    animateCSS('#topImgBox', animFadeInDown).then((message) => {
      const el = document.getElementById('topImgBox');
      const elImage = el.innerHTML;
      const elPlaceholder = document.getElementById('topImgBoxPlaceholder');
      elPlaceholder.innerHTML = elImage;
      elPlaceholder.classList.remove('invisible');
      elPlaceholder.classList.add('visible');
    });

  }

  // const handleMakeAnimation = () => {
  //   animateCSS('#vehicleInfoBoxMakePlaceholder', animFadeOutDown).then((message) => {
  //     //
  //   });

  //   animateCSS('#vehicleInfoBoxMake', animFadeInDown).then((message) => {
  //     const el = document.getElementById('vehicleInfoBoxMake');
  //     const elImage = el.innerHTML;
  //     const elPlaceholder = document.getElementById('vehicleInfoBoxMakePlaceholder');
  //     elPlaceholder.innerHTML = elImage;
  //     elPlaceholder.classList.remove('invisible');
  //     elPlaceholder.classList.add('visible');
  //   });
  // }
  const handleMakeAnimation = () => {
    const elP = document.getElementById('vehicleInfoBoxMakePlaceholder');
    const elem = document.getElementById('vehicleInfoBoxMake');
    elP.classList.add(animationDurationFaster)
    elem.classList.add(animationDurationFaster)
    animateCSS('#vehicleInfoBoxMakePlaceholder', animFadeOutDown).then((message) => {
      //
      elem.classList.remove('invisible')
      elem.classList.add('visible')
      elP.classList.add('invisible')
      //elP.classList.add(animationDurationFast)
      //elem.classList.add(animationDurationFast)

      animateCSS('#vehicleInfoBoxMake', animFadeInDown).then((message) => {
        const el = document.getElementById('vehicleInfoBoxMake');
        const elImage = el.innerHTML;
        const elPlaceholder = document.getElementById('vehicleInfoBoxMakePlaceholder');
        elPlaceholder.innerHTML = elImage;
        elPlaceholder.classList.remove('invisible');
        elPlaceholder.classList.add('visible');
        el.classList.remove('visible');
        el.classList.add('invisible');
      });
    });

    
  }

  const handleModelAnimation = () => {
    const elP = document.getElementById('vehicleInfoBoxModelPlaceholder');
    const elem = document.getElementById('vehicleInfoBoxModel');
    elP.classList.add(animationDurationFaster)
    elem.classList.add(animationDurationFaster)
    animateCSS('#vehicleInfoBoxModelPlaceholder', animFadeOutDown).then((message) => {
      //
      elem.classList.remove('invisible')
      elem.classList.add('visible')
      elP.classList.add('invisible')

      animateCSS('#vehicleInfoBoxModel', animFadeInDown).then((message) => {
        const el = document.getElementById('vehicleInfoBoxModel');
        const elImage = el.innerHTML;
        const elPlaceholder = document.getElementById('vehicleInfoBoxModelPlaceholder');
        elPlaceholder.innerHTML = elImage;
        elPlaceholder.classList.remove('invisible');
        elPlaceholder.classList.add('visible');
        el.classList.remove('visible');
        el.classList.add('invisible');
      });
    });
  }

  const handlePriceAnimation = () => {
    const elP = document.getElementById('vehicleInfoBoxPricePlaceholder');
    const elem = document.getElementById('vehicleInfoBoxPrice');
    elP.classList.add(animationDurationFaster)
    elem.classList.add(animationDurationFaster)
    animateCSS('#vehicleInfoBoxPricePlaceholder', animFadeOutDown).then((message) => {
      //
      elem.classList.remove('invisible')
      elem.classList.add('visible')
      elP.classList.add('invisible')
      elP.classList.add(animationDurationFaster)
      elem.classList.add(animationDurationFaster)

      animateCSS('#vehicleInfoBoxPrice', animFadeInDown).then((message) => {
        const el = document.getElementById('vehicleInfoBoxPrice');
        const elImage = el.innerHTML;
        const elPlaceholder = document.getElementById('vehicleInfoBoxPricePlaceholder');
        elPlaceholder.innerHTML = elImage;
        elPlaceholder.classList.remove('invisible');
        elPlaceholder.classList.add('visible');
        el.classList.remove('visible');
        el.classList.add('invisible');
      });
    }); 
  }

  const handleDetailBlocksAnimation = () => {
    const elP = document.getElementById('detailBlocksBoxPlaceholder');
    const elem = document.getElementById('detailBlocksBox');
    elP.classList.add(animationDurationFaster)
    elem.classList.add(animationDurationFaster)
    elem.classList.add('invisible'); //
    elP.classList.remove('invisible'); //
    elP.classList.add('visible'); //
    //console.log('=====>', document.querySelector('#detailBlocksBoxPlaceholder #qrBlockBox'))
    animateCSS('#detailBlocksBoxPlaceholder #spinnerBlockBox', animFlipOutX);
    animateCSS('#detailBlocksBoxPlaceholder #imagesBlockBox', animFlipOutX);
    //animateCSS('#detailBlocksBoxPlaceholder #spinnerBlockBox', animFlipOutX);
    //animateCSS('#detailBlocksBoxPlaceholder #spinnerBlockBox', animFlipOutX).then((message) => {
    animateCSS('#detailBlocksBoxPlaceholder #qrBlockBox', animFlipOutX).then((message) => {
      //
      elem.classList.remove('invisible')
      elem.classList.add('visible')
      elP.classList.add('invisible')
      elP.classList.add(animationDurationFaster)
      elem.classList.add(animationDurationFaster)

      
      animateCSS('#detailBlocksBox', animFadeInUp).then((message) => {
        const el = document.getElementById('detailBlocksBox');
        const elImage = el.innerHTML;
        const elPlaceholder = document.getElementById('detailBlocksBoxPlaceholder');
        elPlaceholder.innerHTML = elImage;
        //elPlaceholder.classList.remove('invisible');
        //elPlaceholder.classList.add('visible');
        //el.classList.remove('visible');
        //el.classList.add('invisible');
        // setTimeout(() => { 
        //   el.classList.remove('invisible');
        //   el.classList.add('visible');
        //   el.classList.add('z-50');
        //  },1000)
      });
    }); 
  }

  useEffect(() => {
    // Set elements to animate on load
    //
    setAnimateBase('topImgBox');
  }, [])
  

  useEffect(() => {
    setViewMode("default");
    
    // Handle animations on car change
    // 
    handleTopImgAnimation();
    handleMakeAnimation();
    handleModelAnimation();
    handlePriceAnimation();
    // viewMode !== 'spinner' && handleDetailBlocksAnimation();
    viewMode === 'default' && handleDetailBlocksAnimation();
  }, [car]);

  useEffect(() => {
    // Change language on cloned anim divs
    const priceEl = document.getElementById('vehicleInfoBoxPrice');
    const priceElContent = priceEl.innerHTML;
    const priceElPlaceholder = document.getElementById('vehicleInfoBoxPricePlaceholder');
    priceElPlaceholder.innerHTML = priceElContent;
  }, [state])
  

    const grid_order = ["col-start-1", "col-start-2"];
    const image_qr_order = ["col-end-6", "col-start-1"];

    const [grid_order_randomized, setgrid_order_randomized] = useState(
      grid_order[0]
    );
    const [image_qr_ordered_randomized, setimage_qr_ordered_randomized] =
      useState(image_qr_order[0]);

    useEffect(() => {
      setgrid_order_randomized(grid_order.sort(sortArrayRandom));
      setimage_qr_ordered_randomized(image_qr_order.sort(sortArrayRandom));
    }, [listUrl]);

  return (
    <div class="flex-1 justify-between relative">
      <div
        className={`relative z-50 ${
          viewMode === "spinner"
            ? "opacity-0"
            : "animate__animated animate__fadeInDown animate__faster"
        }`}
      >
        <div
          id="topImgBox"
          className={`h-[42rem] w-[42rem] absolute z-50`}
          style={{ top: boundingBox.top - 250, right: "5%" }}
        >
          <img class="object-contain" src={image_uri} alt="car visualization" />
        </div>
        <div
          id="topImgBoxPlaceholder"
          className="h-[42rem] w-[42rem] absolute invisible"
          style={{ top: boundingBox.top - 250, right: "5%", zIndex: 51 }}
        >
          {/* <img class="object-contain" src={image_uri} alt="car visualization" /> */}
        </div>
      </div>
      {/* {viewMode !== "spinner" && ( */}
      <div
        className={`w-[32vw] absolute z-50 pl-20 ${
          viewMode === "spinner"
            ? "opacity-0"
            : "animate__animated animate__zoomIn animate__faster"
        }`}
        style={{ top: boundingBox.top - boundingBox.top / 3 }}
      >
        <img
          src={logoMyway}
          alt="myway logo"
          class="object-contain"
          onClick={() => {
            if (DEVELOPMENT_MODE) {
              navigate("/");
            }
          }}
        />
      </div>

      <div
        class="flex-1 flex-col flex relative h-[50vh] rounded-tr rounded-br rounded-bl"
        ref={coloredDetailRef}
        style={{
          top: boundingBox.top,
          background: `linear-gradient(to right, ${colorScheme?.gradient?.left}, ${colorScheme?.gradient?.right})`,
        }}
      >
        {/* {viewMode !== "spinner" ? (
          <> */}
        <div
          class={`w-3/4 px-20 pt-20 pb-10 ${
            viewMode === "spinner"
              ? "hidden"
              : "animate__animated animate__fadeIn animate__faster"
          }`}
        >
          <div class="flex flex-col">
            <div className="relative h-20">
              <div
                id="vehicleInfoBoxMake"
                class="text-7xl font-light absolute top-0 left-0 invisible"
              >
                {make}
              </div>
              <div
                id="vehicleInfoBoxMakePlaceholder"
                class="text-7xl font-light invisible absolute top-0 left-0"
              ></div>
            </div>
            <div className="relative h-20">
              <div
                id="vehicleInfoBoxModel"
                class="text-7xl absolute top-0 left-0 invisible"
              >
                {model}
              </div>
              <div
                id="vehicleInfoBoxModelPlaceholder"
                class="text-7xl invisible absolute top-0 left-0"
              ></div>
            </div>
            <div className="relative h-20">
              <span
                id="vehicleInfoBoxPrice"
                class="text-md align-baseline mt-4 absolute top-0 left-0 invisible flex"
              >
                <span>{t("available from")} </span>
                <span class="text-4xl font-semibold mx-2 leading-3">
                  {minPrice?.toLocaleString(i18n.language)}
                  {" €"}
                </span>{" "}
                {t("BTWi")}
              </span>
              <span
                id="vehicleInfoBoxPricePlaceholder"
                class="text-md align-baseline mt-4 absolute top-0 left-0 invisible flex"
              >
                <span>{t("available from")} </span>
                <span class="text-4xl font-semibold mx-2 leading-3">
                  {minPrice?.toLocaleString(i18n.language)}
                  {" €"}
                </span>{" "}
                {t("BTWi")}
              </span>
            </div>
          </div>
        </div>
        <div
          class={`relative flex-1 ${viewMode === "spinner" ? "hidden" : ""}`}
        >
          {viewMode === "default" ? (
            DetailBlocks(
              listUrl,
              spinnerUrl,
              images,
              count,
              colorScheme,
              () => setViewMode("images"),
              () => setViewMode("spinner"),
              image_qr_ordered_randomized,
              grid_order_randomized
            )
          ) : (
            <>
              {ImageViewer(images)}

              <div class="fixed bottom-[10vh] z-[100]">
                {CloseButton(() => {
                  const imagesBox = document.getElementById("imagesBox");
                  imagesBox.classList.add("animate__fadeOutRightBig");
                  setTimeout(() => setViewMode("default"), 550);
                })}
              </div>
            </>
          )}
        </div>
        {/* </>
        ) : (
          <> */}
        <div
          id="fullSpinnerBox"
          class={`relative flex flex-1 self-center grow rounded h-2/3 w-2/3 top-[-25vh] ${
            viewMode !== "spinner"
              ? "hidden"
              : "animate__animated animate__zoomIn animate__faster"
          }`}
        >
          <iframe
            title="fullSpinner"
            class="rounded pointer-events-none z-50 flex-grow"
            scrolling="no"
            seamless
            src={spinnerUrl}
            height="200%"
            width="auto"
          />
        </div>
        <div
          class={`fixed bottom-[10vh] z-[100] ${
            viewMode !== "spinner" ? "hidden" : ""
          }`}
        >
          {CloseButton(() => {
            const fullSpinnerBox = document.getElementById("fullSpinnerBox");
            fullSpinnerBox.classList.remove("animate__zoomIn");
            fullSpinnerBox.classList.add("animate__zoomOut");
            setTimeout(() => setViewMode("default"), 550);
          })}
        </div>

        {viewMode === "default" && (
          <>
            <img
              class="self-center fixed bottom-0 h-[8vh] w-auto"
              onClick={onClickNext}
              src={arc}
              alt="next button"
            />
            <img
              class="self-center fixed top-0 h-[8vh] w-auto rotate-180"
              onClick={onClickPrevious}
              src={arc}
              alt="previous button"
            />
          </>
        )}
      </div>
    </div>
  );
}
