import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../utils';

const RadioWithText = ({ checked, checkedClassNames, onClick, label, labelClassNames, classNames, errorText, additional, enabled=true }) => {
  const [isChecked, setIsChecked] = useState(checked);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className={`flex items-center justify-start cursor-pointer ${classNames} ${(!enabled && !checked) ? 'opacity-50' : ''}`} onClick={(e) => (enabled || checked) ? onClick(!isChecked, e) : undefined}>

      <div className="flex items-center justify-center relative">
        <input className={`flex items-center justify-center flex-shrink-0	w-8 h-8 min-w-8 min-h-8 rounded-full border hover:border-green-dark text-transparent outline-none ${isChecked || isFocused ? 'border-green-dark' : errorText ? 'border-red' : 'border-gray'}`}
               onFocus={() => setIsFocused(true)}
               onBlur={() => setIsFocused(false)}/>
        <div className={`absolute w-4 h-4 min-w-4 min-h-4 rounded-full transition duration-150 ease-in-out ${isChecked && checkedClassNames ? checkedClassNames : isChecked ? 'bg-green' : 'bg-white'}`} />
      </div>

      {additional && additional.type === 'img' && (
        <div className='flex items-center justify-center ml-4 w-10 h-6'>
          <img src={additional.src} className='object-cover' />
        </div>
      )}

      <div className={`ml-4 text-lg text-gray-darker font-regular ${labelClassNames}`}>{capitalizeFirstLetter(label)}</div>
    </div>
  );
};

export default RadioWithText;
