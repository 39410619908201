import React from 'react';
import {times} from 'lodash';

const StepIndicator = ({currentStep, totalSteps}) => {
    if(!currentStep || !totalSteps) return <div className="hidden"/>

    return (
        <div className="flex h-1 w-full bg-gray-light" >
            {times(totalSteps, step => {
                const isSelected = step + 1 === currentStep
                return <div key={`questions-step-${step}`} className={`h-1 ${isSelected ? 'bg-gray-darker' : 'bg-transparent'}`}
                            style={{width: `calc(100% / ${totalSteps})`}}
                />
            })}
        </div>
    );
};

export default StepIndicator;
