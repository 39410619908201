import {filter, uniq, map, keys, union, find, unionBy, intersectionBy, includes, orderBy} from 'lodash';

export const actionTypes = {
    VEHICLES_SET: 'VEHICLES_SET',
    FILTERS_SET: 'FILTERS_SET',
    FILTERS_RESET: 'FILTERS_RESET',
    FILTER_ADD: 'FILTER_ADD',
    FILTER_REPLACE: 'FILTER_REPLACE',
    FILTER_DELETE: 'FILTER_DELETE',
    CURRENT_SCROLL_POSITION_SET: 'CURRENT_SCROLL_POSITION_SET',
    NEXT_BATCH_FETCHING: 'NEXT_BATCH_FETCHING'
}

export const initialState = {
    vehicles: undefined,
    pageInfo: undefined,
    currentScrollPosition: undefined,
    filters: [],
    filterData: {},
    doubles: [],
    totalCount: 0,
    fetching: false,
    startFetchNextBatch: false,
    nextBatchStartCursor: undefined,
    lastBatchStartCursor: undefined,
}

const updateFilterData = (filters, currentFilterData, newFilterData) => {

    if (keys(currentFilterData).length === 0) {
        // Initial first load , store all filters as availble
        return {
            ...newFilterData,
            fuels: orderBy(map(newFilterData.fuels, (f) => {
                try {
                    const translations = JSON.parse(f.translations)
                    return ({...f, translations: translations, description: translations[newFilterData.language], available: true})
                } catch (ex) {
                    return ({...f, translations: {}, available: true})
                }
            }), 'description'),
            makes: orderBy(map(newFilterData.makes, (m) => {
                return ({...m, available: true})
            }), 'description'),
            priceRanges: map(newFilterData.priceRanges, (range) => {
                return ({...range, available: true})
            })
        }

    } else {
        let available_fuel_keys = []
        let available_make_keys = []
        let available_price_ranges = []

        const hasFuelFilter = find(filters, (f) => f.key === "fuel")
        if (hasFuelFilter) {
            available_fuel_keys = map(filter(currentFilterData.fuels, (f) => (f.available)), (f) => f.key)
        } else {
            available_fuel_keys = map(intersectionBy(newFilterData.fuels, currentFilterData.fuels, 'key'), (f) => f.key)
        }

        const hasMakeFilter = find(filters, (f) => f.key === "makeModel")
        if (hasMakeFilter) {
            available_make_keys = map(filter(currentFilterData.makes, (f) => (f.available)), (f) => f.key)
        } else {
            available_make_keys = map(intersectionBy(newFilterData.makes, currentFilterData.makes, 'key'), (f) => f.key)
        }

        const hasPriceFilter = find(filters, (f) => f.key === "price") ? true : false

        return {
            ...newFilterData,
            fuels: orderBy(map(currentFilterData.fuels, (f) => {
                if (f.translations && f.translations[newFilterData.language] !== undefined) {
                    return ({...f, description: f.translations[newFilterData.language], available: includes(available_fuel_keys, f.key)})
                } else {
                    return ({...f, available: includes(available_fuel_keys, f.key)})
                }
            }), 'description'),
            makes: orderBy(map(currentFilterData.makes, (m) => {
                return ({...m, available: includes(available_make_keys, m.key)})
            }), 'description'),
            priceRanges: map(newFilterData.priceRanges, (r) => {
                return ({...r, available: r.count > 0 || hasPriceFilter})
            })
        }
    }
}


export const dataReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.VEHICLES_SET:

            const vehicles = action.payload.isNextBatch ? [...state.vehicles, ...action.payload.vehicles] : [...action.payload.vehicles]

            // Temp to check if no duplicates....
            const duplicateIds = vehicles
                .map(e => e['reference'])
                .map((e, i, final) => final.indexOf(e) !== i && i)
                .filter(obj => vehicles[obj])
                .map(e => vehicles[e]["reference"])

            const duplicate = vehicles.filter(obj => duplicateIds.includes(obj.reference));

            return {
                ...state,
                vehicles: vehicles,
                totalCount: action.payload.totalCount,
                doubles: duplicate,
                pageInfo: action.payload.pageInfo,
                filterData: updateFilterData(state.filters, state.filterData, action.payload.filterData),
                lastBatchStartCursor: state.nextBatchStartCursor,
                nextBatchStartCursor: (action.payload.pageInfo.hasNextPage) ? action.payload.pageInfo.endCursor : undefined,
                fetching: false,
                startFetchNextBatch: false,
            }
        case actionTypes.NEXT_BATCH_FETCHING:

            if(state.fetching) {
                return state
            }

            return {
                ...state, startFetchNextBatch: true
            }
        case actionTypes.CURRENT_SCROLL_POSITION_SET:
            return {
                ...state, currentScrollPosition: {
                    top: action.payload.top, left: action.payload.left,
                },
            }
        case actionTypes.FILTER_ADD:
            return {
                ...state, filters: [...state.filters, {"key": action.payload.key, "value": action.payload.value}]
            }
        case actionTypes.FILTER_REPLACE:
            return {
                ...state, filters: [...filter(state.filters, (f) => f.key !== action.payload.key), {"key": action.payload.key, "value": action.payload.value}]
            }
        case actionTypes.FILTER_DELETE:
            if (!Array.isArray(action.payload.value)) {
                return {
                    ...state, filters: filter(state.filters, (f) => f.key !== action.payload.key || f.value !== action.payload.value)
                }
            } else {
                return {
                    ...state, filters: filter(state.filters, (f) => f.key !== action.payload.key || (f.value[0] !== action.payload.value[0] && f.value[1] !== action.payload.value[1]))
                }
            }
        case actionTypes.FILTERS_SET:
            return {
                ...state, filters: action.payload.filters
            }
        case actionTypes.FILTERS_RESET:
            return {
                ...state, filters: []
            }
        default:
            throw new Error();
    }

}
