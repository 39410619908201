import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {DataContext} from "../../../index";
import carLoader from '../../../assets/choose/car-loader-transparant.gif'

const StartScreen = ({onStart}) => {
    const {t} = useTranslation();
    const {state} = useContext(DataContext);
    return (
        <div className="w-full h-screen bg-gray-darker pt-[104px] text-white">
            <div className="flex flex-col items-center justify-center text-white w-full h-full">
                <div className="h-[202px]">
                    <img src={carLoader} alt="" className="w-full h-full"/>
                </div>


                <div className="flex items-center justify-center font-semibold text-[120px] text-transparent bg-clip-text myway-mesh-vertical ">
                    My Way Match!
                </div>

                <div className="mt-12 text-center text-lg mb-6">
                    <div>{t('Saloon or SUV, hybrid or electric, German or Japanese…?')}</div>
                    <div>{t('It is not always easy to choose your new used car.')}</div>
                </div>

                <div className="text-center text-lg mb-6">
                    <div>{t('That is why we have designed this tool especially for you.')}</div>
                    {state.language !== "fr" &&
                    <div>{t('This way you can finally see the forest through the trees again.')}</div>}
                </div>

                <div className="text-center text-lg mb-12">
                    <div>{t('Can we be your guide?')}</div>
                </div>

                <div className="btn-primary text-[15px] font-normal h-14 px-10 flex items-center justify-center hover:bg-opacity-90 transition cursor-pointer rounded-lg"
                     onClick={onStart}>
                    {t('Find my match')}
                </div>
            </div>
        </div>
    );
};

export default StartScreen;
