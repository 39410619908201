import {actionTypes as promoActionTypes, dataReducer as promoDataReducer, initialState as promoInitialState} from '../components/promo/reducer'
import i18n from "i18next";
import {DEFAULT_LANGUAGE} from "../settings";

export const actionTypes = {
    LANGUAGE_SET: 'LANGUAGE_SET',
    RESET_ALL: 'RESET_ALL',
}

export const initialState = {
    language: DEFAULT_LANGUAGE,
    promo: {...promoInitialState}
}

export const rootReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.LANGUAGE_SET:
            i18n.changeLanguage(action.payload.language);
            return {
                ...state,
                language: action.payload.language,
                lastLanguageChanged: Date.now()
            }
        case actionTypes.RESET_ALL:
            i18n.changeLanguage(initialState.language);
            return {
                ...initialState
            }
        case promoActionTypes.VEHICLES_SET:
        case promoActionTypes.FILTERS_SET:
        case promoActionTypes.FILTERS_RESET:
        case promoActionTypes.CURRENT_SCROLL_POSITION_SET:
        case promoActionTypes.FILTER_ADD:
        case promoActionTypes.FILTER_DELETE:
        case promoActionTypes.FILTER_REPLACE:
        case promoActionTypes.NEXT_BATCH_FETCHING:
            return {
                ...state,
                promo: promoDataReducer(state.promo, action)
            }

        default:
            throw new Error();
    }

}
