import React, {useContext, useEffect} from 'react';
import VehicleDetail from "../components/promo/vehicle/detail/VehicleDetail";
import logoSalonDeals from "../assets/MyWay-SalonDeals-2023.png";
import {Link} from "react-router-dom";
import IdleWrapper from "../common/IdleWrapper";
import {DEVELOPMENT_MODE} from "../settings";
import { useNavigate } from "react-router-dom";
import {resetALlHandler} from "../state/actions";
import {DataContext} from "../index";

const PromoDetail = (props) => {
    const {state, dispatch} = useContext(DataContext);
    const navigate = useNavigate();

    let backUrl = `/promo/list`
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tmpParams = urlSearchParams.toString()
    backUrl = (tmpParams !== "") ? `${backUrl}?${tmpParams}` : backUrl

    return (
        <IdleWrapper handleOnIdle={() => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            navigate({
                pathname: '/promo/list',
                search:  urlSearchParams.toString(),
            });
            dispatch(resetALlHandler())
        }}>
            <div className="h-screen overflow-hidden">
                <div className="grid grid-cols-3 items-center h-12.592vh px-3.125vw">
                    <div className="justify-self-start">
                        <Link to={backUrl}>
                            <div className='flex items-center justify-center justify-self-end w-14 h-14 rounded-full cursor-pointer bg-gray-lighter'>
                                <i className='fal fa-arrow-left text-gray-darker text-lg mt-0.5'/>
                            </div>
                        </Link>
                    </div>
                    <div className="justify-self-center">
                        <div className="w-12vw object-contain">
                            <img
                                className="cursor-hand"
                                src={logoSalonDeals}
                                alt="Salon Deals - MyWay - 2023"
                                onClick={() => {
                                    if(DEVELOPMENT_MODE){
                                        navigate('/')
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {/*<div className="justify-self-end">*/}
                    {/*    <LanguagePicker/>*/}
                    {/*</div>*/}
                </div>

                <div className="pl-3.125vw pb-3.125vw h-full overflow-x-auto overflow-y-hidden">
                    <VehicleDetail {...props} />
                </div>
            </div>
        </IdleWrapper>
    );
};

export default PromoDetail;
