import React, {useContext} from 'react';
import OverlayWithSidebar from "../../../../common/OverlayWithSidebar";
import { useTranslation } from "react-i18next";
import {Scrollbars} from "react-custom-scrollbars-2";
import {map, find, includes} from "lodash";
import logoSalonDeals from "../../../../assets/MyWay-SalonDeals-2023.png";
import fuelCard from '../../../../assets/fuelcard.svg';
import warranty from '../../../../assets/icon-warranty.svg';
import {QRCodeSVG} from "qrcode.react";
import {formatNumber} from "../../../../utils";
import {DataContext} from "../../../../index";

const VehicleSideBar = (props) => {
    const {state} = useContext(DataContext);
    const {isOpen, close, vehicle} = props;

    const { t } = useTranslation();

    const salon_labels = [
        {key: "salon_deals_fuel_or_electric_750", type:"fuel", label: t("+1 fuel card of "), labelBold: t("€ 750")},
        {key: "salon_deals_fuel_or_electric_500", type:"fuel", label: t("+1 fuel card of "), labelBold: t("€ 500")},
        {key: "salon_deals_fuel_or_electric_250", type:"fuel", label: t("+1 fuel card of "), labelBold: t("€ 250")},
        {key: "salon_deals_warranty_+4y", type:"warranty", label: t(" of warranty"), labelBold: t("+4 years")},
        {key: "salon_deals_warranty_+3y", type:"warranty", label: t(" of warranty"), labelBold: t("+3 years")},
        {key: "salon_deals_warranty_+2y", type:"warranty", label: t(" of warranty"), labelBold: t("+2 years")},
        {key: "salon_deals_warranty_+1y", type:"warranty", label: t(" of warranty"), labelBold: t("+1 years")},
    ];

    const print_label = (label_key) => {
        if(!label_key || label_key === undefined)
            return

        const salon_promo = find(salon_labels, (label) => label.key === label_key);
        if(salon_promo.type === 'fuel'){

            return (
                <div className="flex whitespace-nowrap font-semibold text-white mb-0.5">
                    <span className='text-lg mr-1'>{salon_promo.label}</span> <strong className='text-xl font-bold'>{salon_promo.labelBold}<sup>*</sup></strong>
                </div>
            );

        }else{

            return (
                <div className="flex whitespace-nowrap font-semibold text-white mb-0.5">
                    <strong className='text-xl font-bold mr-1'>{salon_promo.labelBold}</strong> <span className='text-lg'>{salon_promo.label}<sup>*</sup></span>
                </div>
            );

        }
        
    };

    const renderSidebar = () => {
        return (
          <div className="flex flex-col h-full">
            <div className="grid grid-cols-1 pt-10 pb-10 pr-16 pl-44">
              <div
                className="flex items-center justify-center justify-self-end w-14 h-14 rounded-full cursor-pointer bg-gray-lighter"
                onClick={close}
              >
                <i className="fal fa-times text-gray-darker text-lg mt-0.5" />
              </div>
            </div>

            <div className="flex-1 max-h-full h-full overflow-y-scroll pt-0 pb-12 pl-40 pr-20 no-scrollbar">
              <Scrollbars
                autoHeight={true}
                autoHeightMin={1080}
                //autoHeightMax={1080}
                //autoHide
                //autoHideTimeout={1000}
                //autoHideDuration={200}
              >
                <div className="pl-4 pr-20">
                  <div className="text-3xl font-semibold mb-2">
                    {vehicle.make} {vehicle.model}
                  </div>
                  <div className="text-lg font-regular mb-8">
                    {vehicle.version}
                  </div>
                  <div className="font-semibold flex flex-row items-start mb-6">
                    <span className="text-base mt-0.5 mr-2">€</span>{" "}
                    <span className="text-4xl mr-2">
                      {formatNumber(vehicle.price)}
                    </span>{" "}
                    <span className="text-base mt-0.5">{t("BTWi")}</span>
                  </div>
                  {/* <div className="myway-mesh p-3 mb-3">
                                <div className="bg-white p-3 flex flex-col">
                                    <div className='flex flex-row items-center'>
                                        <div className='flex-grow'>
                                            {print_label(vehicle.salonDeal?.key)}
                                            <div className='text-base font-normal mt-2'>{t('when purchasing this car')}</div>
                                        </div>
                                        <div className="ml-1 w-28 object-contain">
                                            <img
                                                className="cursor-hand"
                                                src={logoSalonDeals}
                                                alt="Salon Deals - MyWay - 2023"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-xs font-regular text-gray-dark mb-12'>
                                <sup className='mr-1'>*</sup>{t('Info and conditions on') + ' www.myway.be/' + state.language + '/salondeals'}
                            </div> */}
                  {vehicle?.salonDeal !== null && (
                    <React.Fragment>
                      <div className="flex flex-col items-center justify-center w-full h-full">
                        <div className="w-full flex flex-row flex-nowrap items-center justify-center h-18 mb-0">
                          <div
                            className="relative z-10 h-20 w-20 flex items-center justify-center bg-white rounded-full px-6"
                            style={{ boxShadow: "-5px -3px 16px #00000029" }}
                          >
                            <div
                              className={`object-fit text-blue ${
                                includes(vehicle?.salonDeal?.key, "fuel")
                                  ? "w-8"
                                  : "w-6"
                              }`}
                            >
                              <img
                                className="text-blue"
                                src={
                                  includes(vehicle?.salonDeal?.key, "fuel")
                                    ? fuelCard
                                    : warranty
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="flex items-center flex-grow -ml-10 pl-14 h-14"
                            style={{
                              background:
                                "transparent linear-gradient(270deg, #2D4B8C 0%, #20A1DB 100%) 0% 0% no-repeat padding-box",
                              borderRadius: 60,
                            }}
                          >
                            {print_label(vehicle.salonDeal?.key)}
                            {/* <div className='text-white font-semibold text-15 leading-2 whitespace-nowrap'>+ 1 carte carburant de <span className='font-bold text-21'>250 €</span><sup className='ml-1'>*</sup></div> */}
                          </div>
                        </div>
                        <div className="w-full flex justify-end -mt-1 pr-4">
                          {/* <div className='font-regular text-13 leading-18'><sup className='mr-1'>*</sup>{t('Info and conditions on myway.be/nl/salondeals')}</div> */}
                          <div className="text-xs font-regular text-gray-dark mb-12">
                            <sup className="mr-1">*</sup>
                            {t("Info and conditions on") +
                              " www.myway.be/" +
                              state.language +
                              "/salondeals"}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="pb-12 border-b border-gray-light mb-12 flex flex-col">
                    <div className="flex flex-row mb-12">
                      <div className="flex-grow text-lg font-semibold">
                        {t("Specifications")}
                      </div>
                      <i className="fa fa-chevron-up text-lg font-regular"></i>
                    </div>
                    <div className="grid grid-cols-3 gap-6">
                      <div className="bg-gray-lighter p-4">
                        <div className="text-base font-regular">
                          {t("Fuel")}
                        </div>
                        <div className="text-lg font-normal">
                          {vehicle.fuel}
                        </div>
                      </div>
                      <div className="bg-gray-lighter p-4">
                        <div className="text-base font-regular">
                          {t("1st registration")}
                        </div>
                        <div className="text-lg font-normal">
                          {vehicle.firstRegistrationYear}
                        </div>
                      </div>
                      <div className="bg-gray-lighter p-4">
                        <div className="text-base font-regular">
                          {t("Transmission")}
                        </div>
                        <div className="text-lg font-normal">
                          {vehicle.gearbox}
                        </div>
                      </div>
                      <div className="bg-gray-lighter p-4">
                        <div className="text-base font-regular">
                          {t("Mileage")}
                        </div>
                        <div className="text-lg font-normal">
                          {vehicle.mileage} km
                        </div>
                      </div>
                      <div className="bg-gray-lighter p-4">
                        <div className="text-base font-regular">
                          {t("Emissions")}
                        </div>
                        <div className="text-lg font-normal">
                          {vehicle.co2} g/km
                        </div>
                      </div>
                    </div>
                  </div>
                  {vehicle.equipmentGroups && (
                    <div className="border-b border-gray-light mb-12 flex flex-col">
                      <div className="flex flex-row mb-12">
                        <div className="flex-grow text-lg font-semibold">
                          {t("Options and equipment")}
                        </div>
                        <i className="fa fa-chevron-up text-lg font-regular"></i>
                      </div>
                      <div className="flex flex-col">
                        {map(vehicle.equipmentGroups, (group) => {
                          return (
                            <div className="mb-12">
                              <div className="text-lg font-semibold mb-2">
                                {group.name}
                              </div>
                              <div>
                                {map(group.equipments, (eq) => {
                                  return (
                                    <div className="text-base font-regular mb-1">
                                      {eq.name}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div className="text-base font-regular">
                    <sup className="mr-1">*</sup>
                    {t("Info and conditions on") +
                      " www.myway.be/" +
                      state.language +
                      "/salondeals"}
                  </div>
                </div>
              </Scrollbars>
            </div>

            <div className="mt-6 py-12 px-44 border-t border-gray-light">
              <div className="text-xl font-semibold mb-7">
                {t("See yourself behind the wheel? We too!")}
              </div>
              <div className="flex flex-row items-center">
                <div className="mr-6">
                  <QRCodeSVG
                    value={`${vehicle.url}&contact-form-open&campaign=SalonDeal`}
                    fgColor={"#183C74"}
                    bgColor={"transparent"}
                    style={{ height: "100%", width: "auto" }}
                  />
                </div>
                <div className="text-base font-regular">
                  {t("Scan the QR-code with your smartphone to contact") +
                    " " +
                    vehicle.dealer.legalName +
                    " " +
                    t("via MyWay.be.") +
                    " " +
                    t("Or save it to your favorites for later.")}
                </div>
              </div>
            </div>
          </div>
        );
    };

    return (
        <React.Fragment>
            <OverlayWithSidebar
                isOpen={isOpen}
                onClose={close}
                animation={true}
                sidebar={renderSidebar()}
                sidebarPosition="right"
                sidebarWidthClass="w-50vw"
                overlayClass="bg-gray-dark"
            />
        </React.Fragment>
    );
}

export default VehicleSideBar
