import React from "react";

export default function ImageViewer(imageList) {
  return (
    <>
      <div id='imagesBox' className="flex flex-row overflow-x-scroll pl-20 no-scrollbar pb-2 animate__animated animate__faster">
        {imageList.map((image) => {
          return (
            <img
              className="mr-4 rounded drop-shadow-md animate__animated animate__fadeInRightBig"
              style={{ height: "33vh" }}
              alt="detail"
              src={image["url"]}
            />
          );
        })}
      </div>
    </>
  );
}
