import React, { useState, useEffect } from "react";
import FilterBadges from "./FilterBadges";
import FiltersBar from "./FiltersBar";
import FiltersSideBar from "./FiltersSideBar";

const FiltersContainer = () => {
  const [UIState, setUIState] = useState({
    sidebarOpen: false,
  });

  const [sidebarOpen, setSidebarOpen] = useState(false);

  //   const handleSidebarOpen = () => {
  //     setUIState({
  //       ...UIState,
  //       sidebarOpen: !UIState.sidebarOpen,
  //     });
  //   };

  const handleSidebarOpen = () => {
    setSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  return (
    <React.Fragment>
      <div className="justify-self-end flex flex-col">
        <FiltersBar openSidebar={handleSidebarOpen} />
        {/* <FilterBadges /> */}
        <FiltersSideBar
          UIState={UIState}
          isOpen={sidebarOpen}
          close={handleSidebarClose}
        />
      </div>
    </React.Fragment>
  );
};

export default FiltersContainer;
