import React, {useContext, useReducer} from 'react';
import HelpMeChoose from "../components/choose/HelpMeChoose";
import {DataContext} from "../index";
import {useNavigate} from "react-router-dom";
import IdleWrapper from "../common/IdleWrapper";
import {clearHandler} from "../components/choose/actions";
import {dataReducer, initialState} from "../components/choose/reducer";

const Choose = (props) => {
    const [data, dispatch] = useReducer(dataReducer, initialState);

    return (
        <IdleWrapper handleOnIdle={() => {
            dispatch(clearHandler())
        }}>
            <HelpMeChoose {...props} data={data} dispatch={dispatch}  />
        </IdleWrapper>
    )
};

export default Choose;
