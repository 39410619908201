import React from "react";

const FilterBadges = () => {
  return (
    <React.Fragment>
      <div className="mt-2.5 flex justify-end">
        <div className="btn btn-pill inline-block text-xs font-normal">
          <i className="fal fa-times mr-2"></i>Clear all filters
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterBadges;
