import React from 'react';
import {DEVELOPMENT_MODE} from "../../../settings";

const Question = ({title, totalResults, isSelected, onClick}) => {

    return (
        <div key={`answer-${title}`}
             className={`w-full border-2 px-6 py-7 text-base text-black rounded-[12px] transition cursor-pointer max-h-[80px] space-x-2 truncate
                         ${isSelected ? 'border-gray-darker' : 'border-gray-light'}`}
             onClick={onClick}
        >
            <span>{title}</span>
            {/*{DEVELOPMENT_MODE && totalResults !== undefined && <span className="text-gray-dark">({totalResults})</span>}*/}
        </div>
    );
};

export default Question;
