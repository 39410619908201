import React from 'react';
import logoWhite from "../../../assets/choose/MY_WAY_LOGO_OUTLINE_WHITE_HORIZONTAL_RGB.svg";
import car1 from "../../../assets/choose/car1.png";
import car2 from "../../../assets/choose/car2.png";
import car3 from "../../../assets/choose/car3.png";
import LanguagePicker from "./LanguagePicker";
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import {DEVELOPMENT_MODE} from "../../../settings";

const Header = ({isStartScreen, onStartAgain, hideLanguagePicker}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="flex items-center justify-between absolute top-0 inset-x-0 px-12 py-8 z-[2]">
            <div>
                <img
                    className="h-8"
                    src={logoWhite}
                    alt="MyWay"
                    onClick={() => {
                        if (DEVELOPMENT_MODE) {
                            navigate('/')
                        }
                    }}
                />
            </div>
            <div className="flex space-x-4">
                {!isStartScreen && <div className="flex items-center py-1 pl-1 pr-4 bg-white border border-gray-light rounded-[100px] h-10 cursor-pointer hover:bg-gray-lighter transition"
                                        style={{boxShadow: '0px 0px 8px #00000014'}}
                                        onClick={() => onStartAgain ? onStartAgain() : undefined}>

                    <div className="flex mr-3">
                        <div className="flex items-center justify-center w-8 h-8 rounded-full bg-green z-[3]">
                            <img className="h-8"
                                 src={car1}
                                 alt="MyWay"
                            />
                        </div>
                        <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-darker -ml-3 z-[2]">
                            <img className="h-8"
                                 src={car2}
                                 alt="MyWay"
                            />
                        </div>
                        <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue -ml-3 z-[1]">
                            <img className="h-8"
                                 src={car3}
                                 alt="MyWay"
                            />
                        </div>
                    </div>
                    <div className="text-[13px] text-darkText">{t('Start matching again')}</div>

                </div>}
                {!hideLanguagePicker && <LanguagePicker/>}
            </div>
        </div>
    );
};

export default Header;
