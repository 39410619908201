import { useRef, useState, createRef, useEffect } from "react";
import { useIsInViewport } from "./utils";
import {
  NUMBER_OF_CARS_IN_VIEW,
  X_TH_CAR_SELECTED_INDEX,
  X_TH_CAR_SELECTED,
  colorSchemes,
} from "../../settings";
import SwipeHand from "../../assets/SwipeHand-2.svg";

const MakeModelCard = (
  index,
  make,
  model,
  image,
  ref,
  selected,
  addToElementsInViewport,
  onClick
) => {
  if (useIsInViewport(ref)) {
    addToElementsInViewport(); // add to list of element in viewport by index
  }

  const viewport_part_element = 100 / NUMBER_OF_CARS_IN_VIEW;

  return (
    <div
      key={index}
      ref={ref}
      class="snap-start"
      style={{ height: `${viewport_part_element}vh` }} //have to do this with inline styles as using a variable in an arbitrary value in tailwind does not work as it's not purgable CSS and is not picked up by tailwind e.g. class={`h-[${viewport_part_element}vh]`} does not work
    >
      {make && (
        <>
          <div
            class={`p-2 h-full flex flex-row px-8 justify-center items-center ${
              selected ? "text-white" : "text-darkText"
            }`}
            onClick={onClick}
          >
            <div class="h-28 w-28 pr-2 flex justify-center">
              <img
                class="object-contain"
                src={image}
                alt={`${make}-${model}`}
              />
            </div>
            <div class="flex-col flex flex-1 text-lg">
              <span>{make}</span>
              <span>{model}</span>
            </div>
          </div>
          <div class="bg-gradient-to-r from-transparent via-gray-200 h-px"></div>
        </>
      )}
    </div>
  );
};

export default function MakeModelList({
  cars,
  onSelect,
  getBoundingBoxSelected,
  setColorScheme,
  colorScheme,
  scrollToNextSignal,
  scrollToPreviousSignal,
  isIdle,
}) {
  const [refToSelect, setrefToSelect] = useState();
  const [listPartToColor, setlistPartToColor] = useState({});
  const [selectedIndex, setselectedIndex] = useState(0);

  useEffect(() => {
    if (refToSelect) {
      const boxOfXthElement = refToSelect.current.getBoundingClientRect();
      getBoundingBoxSelected(boxOfXthElement);
      setlistPartToColor(boxOfXthElement);
    }
  }, [refToSelect]);

  const emptyCarForList = { make: "", model: "" };

  //Add empty cards at begin and end so a user can reach the first/last real cars
  const emptyCars_start = Array(X_TH_CAR_SELECTED - 1).fill(emptyCarForList);
  const emptyCars_end = Array(NUMBER_OF_CARS_IN_VIEW - X_TH_CAR_SELECTED).fill(
    emptyCarForList
  );
  cars = [...emptyCars_start, ...cars, ...emptyCars_end];
  const carRefs = cars.map(createRef);
  const refs = useRef(carRefs);

  let elementsInViewport = [];
  let indexesInViewport = [];

  useEffect(() => {
    if (elementsInViewport.length) {
      //empty list first couple of ms

      onSelect(elementsInViewport[X_TH_CAR_SELECTED_INDEX]);
      setselectedIndex(indexesInViewport[X_TH_CAR_SELECTED]);
      setColorScheme(colorSchemes[selectedIndex % colorSchemes.length]);
    }
  }, [elementsInViewport]);

  //dirty way to scroll to next
  useEffect(() => {
    if (scrollToNextSignal && refs.current) {
      const index = selectedIndex - X_TH_CAR_SELECTED + 1;
      let behavior;
      if (index >= 0) {
        let newRef;
        if (index + NUMBER_OF_CARS_IN_VIEW - 1 === refs.current.length) {
          // if end reached go to start
          newRef = refs.current[0];
          behavior = "auto";
        } else {
          newRef = refs.current[index];
          behavior = "smooth";
        }
        newRef.current.scrollIntoView({ behavior: behavior });
      }
    }
  }, [scrollToNextSignal]);

  useEffect(() => {
    if (scrollToPreviousSignal && refs.current) {
      const originalIndex = selectedIndex - X_TH_CAR_SELECTED;
      let newRef;
      let behavior;
      if (originalIndex === 0) {
        // if end reached go to start
        newRef = refs.current[refs.current.length - 1];
        behavior = "auto";
      } else {
        newRef = refs.current[originalIndex - 1];
        behavior = "smooth";
      }
      newRef.current.scrollIntoView({ behavior: behavior });
    }
  }, [scrollToPreviousSignal]);

  return (
    <div
      class="w-[22.3vw] overflow-auto no-scrollbar"
      style={{ overscrollBehavior: "none" }}
    >
      <div
        class="absolute -z-50 rounded-tl rounded-bl"
        style={{
          left: listPartToColor.left,
          top: listPartToColor.top,
          right: listPartToColor.right,
          bottom: listPartToColor.bottom,
          x: listPartToColor.x,
          y: listPartToColor.y,
          width: listPartToColor.width,
          height: listPartToColor.height,
          backgroundColor: colorScheme?.gradient?.left,
        }}
      ></div>
      <div
        class="snap-y snap-mandatory overflow-auto h-screen no-scrollbar"
        style={{ overscrollBehavior: "none" }}
      >
        {cars.map((car, indexInList) => {
          const ref = refs.current[indexInList];
          const scrollInViewRef = refs.current[indexInList-X_TH_CAR_SELECTED+1]
          if (indexInList === X_TH_CAR_SELECTED_INDEX) {
            if (!refToSelect) {
              //only set the fixed colored 'selectbox' once
              setrefToSelect(ref);
            }
          }
          const image_uri = car.noBackgroundImageUrl;


          return MakeModelCard(
            indexInList,
            car.make,
            car.model,
            image_uri,
            ref,
            indexInList === selectedIndex - 1,
            () => {
              elementsInViewport.push(car);
              indexesInViewport.push(indexInList);
            }, //all elements that are in viewport get added to the list
            () => {scrollInViewRef.current.scrollIntoView({ behavior: "smooth" });}
          );
        })}
      </div>
      {isIdle && (

          <img
            class="fixed bottom-20 animate-bounce left-[13vw]"
            src={SwipeHand}
            alt="swipe list"
            style={{ height: "5rem", width: "auto" }}
          />

      )}
    </div>
  );
}
