import React, {useContext, useEffect, useState} from "react";
import {DataContext} from "../index";
import {map, chunk, forEach, times} from "lodash";
import {languageSetHandler, promoMakeSetHandler} from "../state/actions";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {isElementInViewport} from "../utils";
import logoSalonDeals from "../assets/MyWay-SalonDeals-2023.png";
import i18n from "i18next";
import {AVAILABLE_LANGUAGES} from "../settings";


const VEHICLE_IMAGES_QUERY = `query PromoVehicleSearch($first: Int, $after: String, $shuffle: Boolean, $makeModelFamily: String) {
    promoVehicleSearch(first: $first, after: $after, shuffle: $shuffle, makeModelFamily: $makeModelFamily) {
        edges {
            node {                
                thumbnailUrl
            }
        }
         pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
    }
}`;

const addPromoCardToVehiclesList = (indexes, currentList, startLanguage = "nl", separatorType = 'promo-card') => {
    let result = [];
    result = map(currentList, (vehicle) => {
        return {url: vehicle, type: 'vehicle'};
    });

    let lng = startLanguage
    forEach(indexes, (index, i) => {
        lng = (i > 0) ? defineLanguage(lng) : startLanguage;
        const customCard = {type: separatorType, lng: lng};
        result.splice(index, 0, customCard);
    });
    return result;
};

const addPromoCardToVehiclesListV2 = (indexes, currentList, language = "nl", separatorType = 'promo-card') => {
    let result = [];
    result = map(currentList, (vehicle) => {
        return {url: vehicle, type: 'vehicle'};
    });

    forEach(indexes, (index, i) => {
        const customCard = {type: separatorType, lng: language};
        result.splice(index, 0, customCard);
    });
    return result;
};

const column1InitialYPosition = -300;
const column2InitialYPosition = -150;
const column3InitialYPosition = 0;
const intervalTime = 2000; // in miliseconds
const positionValue = 50; // in px

const defineLanguage = (currentLanguage) => {
    const language1 = AVAILABLE_LANGUAGES[0]
    const language2 = AVAILABLE_LANGUAGES[1]

    return (currentLanguage === language1) ? language2 : language1;
}

const resolve_positions = (start, count) => {
    let result = []
    for (let i = 0; i < 150 / count; i++) {
        result.push(((i * count) + start))
    }
    return result
}

const Promo = (props) => {
    const {state, dispatch} = useContext(DataContext);
    const [vehicleImages, setVehicleImages] = useState(undefined);
    const [pageInfo, setPageInfo] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(undefined)
    const navigate = useNavigate();
    const {t} = useTranslation()

    const [column1YPosition, setColumn1YPosition] = useState(column1InitialYPosition);
    const [column2YPosition, setColumn2YPosition] = useState(column2InitialYPosition);
    const [column3YPosition, setColumn3YPosition] = useState(column3InitialYPosition);

    let intervalScrollToBottom;
    let intervalScrollToTop;

    useEffect(() => {
        if (!loading) {
            setTimeout(() => startScrollInterval(), 2000)
            return () => {
                removeScrollToBottomInterval();
                removeScrollToTopInterval();
            }
        }
    }, [loading]);

    const calculateNewPosition = (currentPosition, maxPosition, minPosition, direction) => {
        if (direction === 'to-bottom') {
            let newPosition = currentPosition - positionValue;
            newPosition = -1 * newPosition > maxPosition ? -maxPosition : newPosition;
            return newPosition
        } else if (direction === 'to-top') {
            let newPosition = currentPosition + positionValue;
            newPosition = newPosition > minPosition ? minPosition - 1 : newPosition;
            return newPosition
        }
    }

    const removeScrollToTopInterval = () => {
        clearInterval(intervalScrollToTop);
        intervalScrollToTop = null;
    }

    const removeScrollToBottomInterval = () => {
        clearInterval(intervalScrollToBottom);
        intervalScrollToBottom = null;
    }
    const startScrollInterval = () => {
        const screenHeight = window ? window?.innerHeight : 0;
        const column1Bottom = document.getElementById('column-1-bottom');
        const column1Top = document.getElementById('column-1-top');
        const column1 = document.getElementById('column-1');
        const column1MaxTop = column1 ? column1?.scrollHeight - screenHeight : 0

        const column2Bottom = document.getElementById('column-2-bottom');
        const column2Top = document.getElementById('column-2-top');
        const column2 = document.getElementById('column-2');
        const column2MaxTop = column2 ? column2?.scrollHeight - screenHeight : 0

        const column3Bottom = document.getElementById('column-3-bottom');
        const column3Top = document.getElementById('column-3-top');
        const column3 = document.getElementById('column-3');
        const column3MaxTop = column3 ? column3?.scrollHeight - screenHeight : 0

        const intervalScrollToTopHandler = () => {
            const column1TopReached = isElementInViewport(column1Top);
            const column2TopReached = isElementInViewport(column2Top);
            const column3TopReached = isElementInViewport(column3Top);

            setColumn1YPosition(column1YPosition => {
                return calculateNewPosition(column1YPosition, column1MaxTop, column1InitialYPosition, 'to-top');
            });

            setColumn2YPosition(column2YPosition => {
                return calculateNewPosition(column2YPosition, column2MaxTop, column2InitialYPosition, 'to-top');
            });
            setColumn3YPosition(column3YPosition => {
                return calculateNewPosition(column3YPosition, column3MaxTop, column3InitialYPosition, 'to-top');
            });

            // Top is reached on all three columns. Remove interval to scroll to top and add interval to scroll to bottom
            if (column1TopReached && column2TopReached && column3TopReached) {
                removeScrollToTopInterval();
                // Start scroll to bottom
                intervalScrollToBottom = setInterval(intervalScrollToBottomHandler, intervalTime);
            }
        }

        const intervalScrollToBottomHandler = () => {
            const column1BottomReached = isElementInViewport(column1Bottom);
            const column2BottomReached = isElementInViewport(column2Bottom);
            const column3BottomReached = isElementInViewport(column3Bottom);


            setColumn1YPosition(column1YPosition => {
                return calculateNewPosition(column1YPosition, column1MaxTop, column1InitialYPosition, 'to-bottom');
            });

            setColumn2YPosition(column2YPosition => {
                return calculateNewPosition(column2YPosition, column2MaxTop, column2InitialYPosition, 'to-bottom');
            });
            setColumn3YPosition(column3YPosition => {
                return calculateNewPosition(column3YPosition, column3MaxTop, column3InitialYPosition, 'to-bottom');
            });

            // Bottom is reached on all three columns. Remove interval to scroll to bottom and add interval to scroll to top
            if (column1BottomReached && column2BottomReached && column3BottomReached) {
                removeScrollToBottomInterval();
                // Start scroll to top
                intervalScrollToTop = setInterval(intervalScrollToTopHandler, intervalTime);
            }
        }

        intervalScrollToBottom = setInterval(intervalScrollToBottomHandler, intervalTime);
    }


    const fetchVehicleImages = (variables) => {
        setLoading(true)

        const urlSearchParams = new URLSearchParams(window.location.search);
        if(urlSearchParams.has("make")) {
            let make = urlSearchParams.get('make');
            variables = {
                ...variables,
                makeModelFamily: make
            }
        }

        fetch("/graphql", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": state.language
            },
            body: JSON.stringify({
                query: VEHICLE_IMAGES_QUERY,
                variables: variables
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (vehicleImages === undefined) {
                    setVehicleImages(map(res.data.promoVehicleSearch.edges, (n) => n.node.thumbnailUrl))
                } else {
                    // next batch
                    setVehicleImages([...vehicleImages, ...map(res.data.promoVehicleSearch.edges, (n) => n.node.thumbnailUrl)])
                }

                setPageInfo(res.data.promoVehicleSearch.pageInfo)
                setLoading(false);
            })
            .catch((error) => {
                setError("ERROR");
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchVehicleImages({first: 15, shuffle: true})
    }, []);

    useEffect(() => {
        if (pageInfo && pageInfo.hasNextPage && vehicleImages.length < 150) {
            fetchVehicleImages({first: 135, after: pageInfo.endCursor})
        }
    }, [pageInfo]);

    const handleLanguageClick = (language) => {
        dispatch(languageSetHandler(language))

        const urlSearchParams = new URLSearchParams(window.location.search);
        navigate({
            pathname: "/promo/list",
            search:  urlSearchParams.toString(),
        });
    }

    // Skip splash screen redirect immediately to list
    useEffect(() => {
        dispatch(languageSetHandler('nl'))
        const urlSearchParams = new URLSearchParams(window.location.search);
        navigate({
            pathname: "/promo/list",
            search:  urlSearchParams.toString(),
        });
    }, [])
    

    if (loading) {
        return <div className="relative w-full h-screen overflow-hidden">
            <div className="grid grid-cols-3 px-24 gap-24">
                <div className="relative overflow-hidden h-screen no-scrollbar">
                    <div className="absolute inset-0 grid grid-cols-1 gap-24"
                         style={{top: column1InitialYPosition}}>
                        {times(5, item => {
                            return <div key={`column-1-skeleton-${item}`} className="h-[390px] bg-gray-200 w-full animate-pulse"/>
                        })}
                    </div>
                </div>

                <div className="relative overflow-hidden h-screen no-scrollbar">
                    <div className="absolute inset-0 grid grid-cols-1 gap-24"
                         style={{top: column2InitialYPosition}}>
                        {times(5, item => {
                            return <div key={`column-2-skeleton-${item}`} className="h-[390px] bg-gray-200 w-full animate-pulse"/>
                        })}
                    </div>
                </div>

                <div className="relative overflow-hidden h-screen no-scrollbar">
                    <div className="absolute inset-0 grid grid-cols-1 gap-24"
                         style={{top: column3InitialYPosition}}>
                        {times(5, item => {
                            return <div key={`column-3-skeleton-${item}`} className="h-[390px] bg-gray-200 w-full animate-pulse"/>
                        })}
                    </div>
                </div>

            </div>
        </div>
    }

    if (!loading && error) {
        return <div className="my-10 px-24">
            <div className="rounded bg-red-200 text-red-900 px-8 py-4 text-base">
                {t('Something went wrong.')}
            </div>
        </div>
    }

    const chunkedVehicleImages = chunk(vehicleImages, vehicleImages.length / 3)
    // const column1VehicleImages = addPromoCardToVehiclesList([3, 13, 23, 73], chunkedVehicleImages[0], "nl")
    // const column2VehicleImages = addPromoCardToVehiclesList([8, 30, 45, 55], chunkedVehicleImages[1], "fr")
    // const column3VehicleImages = addPromoCardToVehiclesList([12, 38, 51, 90], chunkedVehicleImages[2], "nl")

    const numberOfCardsInColumn = 2
    const column1VehicleImages = addPromoCardToVehiclesListV2(resolve_positions(1, numberOfCardsInColumn), chunkedVehicleImages[0], "nl")
    const column2VehicleImages = addPromoCardToVehiclesListV2([], chunkedVehicleImages[1], "fr")
    const column3VehicleImages = addPromoCardToVehiclesListV2(resolve_positions(1, numberOfCardsInColumn), chunkedVehicleImages[2], "fr")

    const renderPromoCard = (language) => {
        const t2 = i18n.getFixedT(language);
        return <div className="flex flex-col items-center justify-center w-full py-[4.375rem] px-9">
            <img
                className="h-[104px] mb-10"
                src={logoSalonDeals}
                alt="Salon Deals - MyWay - 2023"
            />
            <div className="text-center">
                <span className="text-4xl font-semibold text-gray-darker leading-[54px]">{t2('Discover our')}{' '}</span>
                <span className="text-4xl font-semibold text-blue leading-[54px]">{t2('Salon Deals')}{' '}</span>
                <span className="text-4xl font-semibold text-gray-darker leading-[54px]">{t2('from 14 to 14 January 22')}</span>
            </div>
        </div>
    }

    return (
        <div className="relative h-screen" onClick={() => handleLanguageClick("nl")}>
            <div className="flex items-end fixed inset-0 z-10">
                <div className="rounded-sm bg-green px-6 py-4 mr-5 text-white rounded ml-16 mb-16 cursor-pointer hover:bg-gray-900 transition"
                     onClick={(e) => {
                         e.preventDefault();
                         e.stopPropagation();
                         handleLanguageClick("nl")
                     }}>Nederlands
                </div>
            </div>
            <div className="flex items-end fixed inset-0 z-10 ml-40">
                <div className="rounded-sm bg-green px-6 py-4 text-white rounded ml-16 mb-16 cursor-pointer hover:bg-gray-900 transition"
                     onClick={(e) => {
                         e.preventDefault();
                         e.stopPropagation();
                         handleLanguageClick("fr")
                     }}>Français
                </div>
            </div>

            <div className="relative w-full h-screen overflow-hidden">
                <div className="grid grid-cols-3 px-24 gap-24">
                    <div className="relative overflow-hidden h-screen no-scrollbar">
                        <div id="column-1" className="absolute inset-0 grid grid-cols-1 gap-24"
                             style={{
                                 transition: "all 6s linear",
                                 top: column1YPosition
                             }}
                        >
                            {map(column1VehicleImages, (vehicleImage, i) => {
                                const isFirst = i === 0;
                                const isLast = i === column1VehicleImages?.length - 1;
                                return <div key={`column-1-vehicle-${i}`} className="relative w-full">
                                    {vehicleImage.type === 'promo-card' ?
                                        renderPromoCard(vehicleImage.lng) :
                                        <img src={vehicleImage.url} alt="Vehicle" className="w-full"/>
                                    }
                                    {isFirst && <div id="column-1-top" className="absolute opacity-1 w-full h-20" style={{top: -1 * column1InitialYPosition}}/>}
                                    {isLast && <div id="column-1-bottom" className="absolute bottom-0 opacity-1 w-full h-20"/>}
                                </div>
                            })}


                        </div>
                    </div>

                    <div className="relative overflow-hidden h-screen no-scrollbar">
                        <div id="column-2" className="absolute inset-0 grid grid-cols-1 gap-24"
                             style={{
                                 transition: "all 5s linear",
                                 top: column2YPosition
                             }}
                        >
                            {map(column2VehicleImages, (vehicleImage, i) => {
                                const isFirst = i === 0;
                                const isLast = i === column2VehicleImages?.length - 1
                                return <div key={`column-2-vehicle-${i}`} className="relative w-full">
                                    {vehicleImage.type === 'promo-card' ?
                                        renderPromoCard(vehicleImage.lng) :
                                        <img src={vehicleImage.url} alt="Vehicle" className="w-full"/>
                                    }
                                    {isFirst && <div id="column-2-top" className="absolute opacity-1 w-full h-20" style={{top: -1 * column2InitialYPosition}}/>}
                                    {isLast && <div id="column-2-bottom" className="absolute bottom-0 opacity-1 w-full h-20"/>}
                                </div>
                            })}


                        </div>
                    </div>

                    <div className="relative overflow-hidden h-screen no-scrollbar">
                        <div id="column-3" className="absolute inset-0 grid grid-cols-1 gap-24"
                             style={{
                                 transition: "all 6s linear",
                                 top: column3YPosition
                             }}
                        >
                            {map(column3VehicleImages, (vehicleImage, i) => {
                                const isFirst = i === 0;
                                const isLast = i === column3VehicleImages?.length - 1
                                return <div key={`column-3-vehicle-${i}`} className="relative w-full">
                                    {vehicleImage.type === 'promo-card' ?
                                        renderPromoCard(vehicleImage.lng) :
                                        <img src={vehicleImage.url} alt="Vehicle" className="w-full"/>
                                    }
                                    {isFirst && <div id="column-3-top" className="absolute opacity-1 w-full h-20" style={{top: -1 * column3InitialYPosition}}/>}
                                    {isLast && <div id="column-3-bottom" className="absolute bottom-0 opacity-1 w-full h-20"/>}
                                </div>
                            })}


                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Promo;
