import React, { useEffect, useState, Fragment } from "react";
//import { hasDocument } from '@digitalexperience/dealer-website-core';
import { toggleDisableBackgroundScroll } from "../utils";

const OverlayWithSidebar = ({
  isOpen,
  onClose,
  overlayClass,
  sidebar,
  sidebarPosition,
  sidebarWidthClass,
  animation,
  context,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  useEffect(() => {
    //if (!hasDocument()) return;
    toggleDisableBackgroundScroll(open);
  }, [open]);

  let animationClass = open ? "block" : "hidden";
  if (animation) {
    const animationDirectionClassName =
      sidebarPosition === "right" ? "translate-x-full" : "-translate-x-full";
    animationClass = `transition-all transform ${
      open ? "translate-x-0" : animationDirectionClassName
    }`;
  }

  const renderBackground = () => {
    // return <div className={`flex justify-end fixed inset-0 bg-${overlayColor} bg-opacity-50 ${animationClass}`}
    //             style={{ zIndex: 7998 }}
    //             onClick={handleClose} />;
    return (
      <div
        className={`flex justify-end fixed inset-0 bg-gray-darker ${overlayClass} opacity-50 ${
          open ? "" : "hidden"
        }`}
        style={{ zIndex: 7998 }}
        onClick={handleClose}
      />
    );
  };

  const renderSidebar = () => {
    return (
      <div
        className={`flex flex-col fixed top-0 ${sidebarPosition}-0 ${sidebarWidthClass} bg-white h-screen max-h-full ${animationClass}`}
        style={{ boxShadow: "0px 3px 6px #00000029", zIndex: 7999 }}
      >
        {sidebar}
      </div>
    );
  };

  return (
    <Fragment>
      {renderBackground()}
      {sidebar && renderSidebar()}
    </Fragment>
  );
};

OverlayWithSidebar.defaultProps = {
  onClose: undefined,
  sidebar: undefined,
  sidebarPosition: "right",
  animation: true,
};

export default OverlayWithSidebar;
