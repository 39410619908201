import {filter, find, map, sumBy, without, head} from "lodash";
import {groupMultipleAnswersAsFilters, resolveQuestion} from "./utils";
import {v4} from 'uuid';
import {initialAnswer} from "../../utils";

export const actionTypes = {
    INITIALIZE: 'INITIALIZE',
    LANGUAGE_SET: 'LANGUAGE_SET',
    RESULTS_SET: 'RESULTS_SET',
    ANSWER_SET: 'ANSWER_SET',
    FETCHING_SET: 'FETCHING_SET',
    SHORT_URL_SET: 'SHORT_URL_SET',
    FILTERS_UPDATE: 'FILTERS_UPDATE',
    CLEAR: 'CLEAR',

    NEXT_QUESTION: 'NEXT_QUESTION',
    PREVIOUS_QUESTION: 'PREVIOUS_QUESTION',
    START_SCREEN_VISIBLE_SET: 'START_SCREEN_VISIBLE_SET',
};

const initialFilters = {
    priceMin: undefined,
    priceMax: undefined,
    seatsMin: undefined,
    seatsMax: undefined,
    co2Min: undefined,
    co2Max: undefined,
    hpMin: undefined,
    hpMax: undefined,
    fuels: undefined,
    labels: undefined,
    makeModelFamily: undefined,
    bodyTypes: undefined
}

const initial_data = {
    result_count: undefined,
    result_url: undefined,
    filter_data: {},
    vehicles: []
}


export const initialState = {
    uniqueId: v4(),
    questions: [
        {
            number: 1,
            type: 'budget',
            title: {
                en: "My budget for a car is",
                nl: "Mijn budget voor een wagen is",
                fr: "Mon budget idéal pour un véhicule est"
            },
            max_answers: 1,
            skip: (filters) => {
                return false
            },
            answers: [
                {
                    title: {
                        en: "Less than 25.000€",
                        nl: "Minder dan 25.000€",
                        fr: "Moins que 25.000",
                    },
                    filters: [
                        {key: "priceMax", value: 25000}
                    ],
                    count: (data) => {
                        return undefined
                    }
                },
                {
                    title: {
                        en: "Between 25.000€ and 30.000€",
                        nl: "Tussen dan 25.000€ en 30.000€",
                        fr: "Entre 25.000€ et 30.000€",
                    },
                    filters: [
                        {key: "priceMin", value: 25000},
                        {key: "priceMax", value: 30000}
                    ],
                    count: (data) => {
                        return undefined
                    }
                },
                {
                    title: {
                        en: "Between 30.000€ and 35.000€",
                        nl: "Tussen dan 30.000€ en 35.000€",
                        fr: "Entre 30.000€ et 35.000€",
                    },
                    filters: [
                        {key: "priceMin", value: 30000},
                        {key: "priceMax", value: 35000}
                    ],
                    count: (data) => {
                        return undefined
                    }
                },
                {
                    title: {
                        en: "Between 35.000€ and 40.000€",
                        nl: "Tussen dan 35.000€ en 40.000€",
                        fr: "Entre 35.000€ et 40.000€",
                    },
                    filters: [
                        {key: "priceMin", value: 35000},
                        {key: "priceMax", value: 40000}
                    ],
                    count: (data) => {
                        return undefined
                    }
                },
                {
                    title: {
                        en: "Between 40.000€ and 45.000€",
                        nl: "Tussen dan 40.000€ en 45.000€",
                        fr: "Entre 40.000€ et 45.000€",
                    },
                    filters: [
                        {key: "priceMin", value: 40000},
                        {key: "priceMax", value: 45000}
                    ],
                    count: (data) => {
                        return undefined
                    }
                },
                {
                    title: {
                        en: "More than 45.000€",
                        nl: "Meer dan 45.000€",
                        fr: "Plus que 45.000€",
                    },
                    filters: [
                        {key: "priceMin", value: 45000},
                    ],
                    count: (data) => {
                        return undefined
                    }
                },
            ]
        },
        {
            number: 2,
            type: 'seats',
            title: {
                en: "How many people in your car?",
                nl: "Hoeveel personen zullen er meestal in de wagen zitten?",
                fr: "Combien de personnes seront dans le véhicule au quotidien?"
            },
            max_answers: 1,
            skip: (filters) => {
                return false
            },
            answers: [
                {
                    title: {
                        en: "Up to 3 people",
                        nl: "Tot 3 personen",
                        fr: "Jusqu'à 3 personne",
                    },
                    filters: [
                        {key: "seatsMax", value: 3},
                    ],
                    count: (data) => {
                        return sumBy(filter(data.seats, (item) => item.value <= 3), 'count');
                    }
                },
                {
                    title: {
                        en: "3 to 5 people",
                        nl: "3 tot 5 personen",
                        fr: "3 à 5 personnes",
                    },
                    filters: [
                        {key: "seatsMin", value: 3},
                        {key: "seatsMax", value: 5}
                    ],
                    count: (data) => {
                        return sumBy(filter(data.seats, (item) => item.value >= 3 && item.value <= 5), 'count');
                    }
                },
                {
                    title: {
                        en: "6 to 8 people",
                        nl: "6 tot 8 personen",
                        fr: "6 à 8 personnes",
                    },
                    filters: [
                        {key: "seatsMin", value: 6},
                        {key: "seatsMax", value: 8}
                    ],
                    count: (data) => {
                        return sumBy(filter(data.seats, (item) => item.value >= 6 && item.value <= 8), 'count');
                    }
                },
                {
                    title: {
                        en: "Cargo",
                        nl: "Vooral cargo",
                        fr: "Surtout transport de marchandises",
                    },
                    filters: [
                        {key: "bodyTypes", value: "transporter"},
                    ],
                    count: (data) => {
                        return sumBy(filter(data.bodyTypes, (item) => item.key === "transporter"), 'count');
                    }
                },
            ],
        },
        {
            number: 3,
            type: 'fuel',
            title: {
                en: "What fuel?",
                nl: "Welk type van aandrijving verkies je?",
                fr: "Quel type de moteur préférez-vous?"
            },
            max_answers: 1,
            skip: (filters) => {
                return false
            },
            answers: [
                {
                    title: {
                        en: "Gasoline",
                        nl: "Benzine",
                        fr: "Essence",
                    },
                    filters: [
                        {key: "fuels", value: "gasoline"},
                    ],
                    count: (data) => {
                        return sumBy(filter(data.fuels, (item) => item.key === "gasoline"), 'count');
                    }
                },
                {
                    title: {
                        en: "Diesel",
                        nl: "Diesel",
                        fr: "Diesel",
                    },
                    filters: [
                        {key: "fuels", value: "diesel"},
                    ],
                    count: (data) => {
                        return sumBy(filter(data.fuels, (item) => item.key === "diesel"), 'count');
                    }
                },
                {
                    title: {
                        en: "CNG",
                        nl: "CNG",
                        fr: "CNG",
                    },
                    filters: [
                        {key: "fuels", value: "cng"},
                    ],
                    count: (data) => {
                        return sumBy(filter(data.fuels, (item) => item.key === "cng"), 'count');
                    }
                },
                {
                    title: {
                        en: "Plug-in Hybrid",
                        nl: "Plug-in Hybrid",
                        fr: "Plug-in Hybride",
                    },
                    filters: [
                        {key: "fuels", value: "electric_diesel,electric_gasoline"},
                    ],
                    count: (data) => {
                        return sumBy(filter(data.fuels, (item) => item.key == "electric_diesel" || item.key === "electric_gasoline"), 'count');
                    }
                },
                {
                    title: {
                        en: "Electric",
                        nl: "Elektrisch",
                        fr: "Electrique",
                    },
                    filters: [
                        {key: "fuels", value: "electric"},
                    ],
                    count: (data) => {
                        return sumBy(filter(data.fuels, (item) => item.key === "electric"), 'count');
                    }
                },
            ],
        },
        {
            number: 4,
            type: 'hp-co2',
            title: {
                en: "What is the most important for you?",
                nl: "Wat is voor jou het belangrijkste?",
                fr: "Quel est le plus important pour vous?"
            },
            max_answers: 1,
            skip: (filters) => {
                return (filters.fuels !== undefined && (filters.fuels.includes('electric_diesel') || filters.fuels.includes('electric_gasoline') || filters.fuels.includes('electric')))
            },
            answers: [
                {
                    title: {
                        en: "Low usage and CO2 emissions",
                        nl: "Laag verbruik en CO2 uitstoot",
                        fr: "Faible consommation et émissions de co2",
                    },
                    filters: [
                        {
                            key: "co2Max", value: 75, evaluate: (filters) => {
                                return (filters.fuels !== undefined && filters.fuels.includes('diesel'))
                            }
                        },
                        {
                            key: "co2Max", value: 91, evaluate: (filters) => {
                                return (filters.fuels !== undefined && (filters.fuels.includes('gasoline') || filters.fuels.includes('cng')))
                            }
                        },
                    ],
                    count: (data, filters) => {
                        let co2Value = 100
                        if (filters && filters.fuels !== undefined && filters.fuels.includes('diesel'))
                            co2Value = 75
                        else if (filters && (filters.fuels !== undefined && (filters.fuels.includes('gasoline') || filters.fuels.includes('cng'))))
                            co2Value = 91

                        return sumBy(filter(data.co2, (item) => item.value <= co2Value), 'count');
                    }
                },
                {
                    title: {
                        en: "Power and speed",
                        nl: "Kracht en snelheid",
                        fr: "Puissance et vitesse",
                    },
                    filters: [
                        {key: "hpMin", value: 200},
                    ],
                    count: (data) => {
                        return sumBy(filter(data.hp, (item) => item.value > 200), 'count');
                    }
                },
                {
                    title: initialAnswer,
                    filters: [],
                    count: (data) => {
                        return undefined
                    }
                },
            ],
        },
        {
            number: 5,
            type: 'lifestyle',
            title: {
                en: "What lifestyle fits you best?",
                nl: "Welke lifestyle past het beste bij jou?",
                fr: "Quel style de vie vous convient le mieux?"
            },
            max_answers: 1,
            skip: (filters) => {
                return false
            },
            answers: [
                {
                    title: {
                        en: "Family",
                        nl: "Familie",
                        fr: "Familiale",
                    },
                    filters: [
                        {key: "labels", value: 'filter:myway_family'}
                    ],
                    count: (data) => {
                        return sumBy(filter(data.labels, (item) => item.key === 'filter:myway_family'), 'count');
                    }
                },
                {
                    title: {
                        en: "City",
                        nl: "Stadsleven",
                        fr: "Urbaine",
                    },
                    filters: [
                        {key: "labels", value: 'filter:myway_city'}
                    ],
                    count: (data) => {
                        return sumBy(filter(data.labels, (item) => item.key === 'filter:myway_city'), 'count');
                    }
                },
                {
                    title: {
                        en: "Planet",
                        nl: "Groen",
                        fr: "Ecologique",
                    },
                    filters: [
                        {key: "labels", value: 'filter:myway_planet'}
                    ],
                    count: (data) => {
                        return sumBy(filter(data.labels, (item) => item.key === 'filter:myway_planet'), 'count');
                    }
                },
                {
                    title: {
                        en: "Sport",
                        nl: "Sportief",
                        fr: "Sportive",
                    },
                    filters: [
                        {key: "labels", value: 'filter:myway_sport'}
                    ],
                    count: (data) => {
                        return sumBy(filter(data.labels, (item) => item.key === 'filter:myway_sport'), 'count');
                    }
                },
                {
                    title: {
                        en: "Workhorse",
                        nl: "Werkpaarden",
                        fr: "Utilitaire",
                    },
                    filters: [
                        {key: "labels", value: 'filter:myway_workhorse'}
                    ],
                    count: (data) => {
                        return sumBy(filter(data.labels, (item) => item.key === 'filter:myway_workhorse'), 'count');
                    }
                },
                {
                    title: {
                        en: "Cabrio",
                        nl: "Open lucht",
                        fr: "Cabriolet",
                    },
                    filters: [
                        {key: "labels", value: 'filter:myway_open'}
                    ],
                    count: (data) => {
                        return sumBy(filter(data.labels, (item) => item.key === 'filter:myway_open'), 'count');
                    }
                },
                {
                    title: {
                        en: "Luxury",
                        nl: "Luxueus",
                        fr: "Luxueuse",
                    },
                    filters: [
                        {key: "labels", value: 'filter:myway_luxury'}
                    ],
                    count: (data) => {
                        return sumBy(filter(data.labels, (item) => item.key === 'filter:myway_luxury'), 'count');
                    }
                },
                {
                    title: {
                        en: "Budget",
                        nl: "Budgetvriendelijk",
                        fr: "Economique",
                    },
                    filters: [
                        {key: "labels", value: 'filter:myway_budget'}
                    ],
                    count: (data) => {
                        return sumBy(filter(data.labels, (item) => item.key === 'filter:myway_budget'), 'count');
                    }
                },
                {
                    title: initialAnswer,
                    filters: [],
                    count: (data) => {
                        return undefined
                    }
                },
            ],
        },
        {
            number: 6,
            type: 'make',
            max_answers: 2,
            title: {
                en: "Make?",
                nl: "Welk merk verkies je?",
                fr: "Quelle marque préférez-vous?"
            },
            skip: (filters, result) => {
                return (result && result.data && result.data.makes) ? result.data.makes.length === 1 : false
            },
            answers: [{
                title: initialAnswer,
                filters: [],
                count: (data) => {
                    return undefined
                },
                priority: 0
            }],
        }
    ],
    result: {
        count: undefined,
        url: undefined,
        url_short: undefined,
        data: {
            makes: undefined,
            fuels: undefined,
            bodyTypes: undefined,
            labels: undefined,
            seats: undefined,
            co2: undefined,
            hp: undefined
        },
        vehicles: []
    },
    filters: initialFilters,
    filtersLastUpdated: undefined,
    currentQuestion: undefined,
    currentAnswers: [],
    allAnswers: [],
    completed: false,
    loading: true,
    fetching: false,
    history: [],
    startScreenVisible: true,
};

export const dataReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE:
        case actionTypes.CLEAR:
            // Get first question and hide loading indicator
            return {
                ...initialState,
                uniqueId: v4(),
                currentQuestion: resolveQuestion(state, 1),
                loading: false,
            }
        case actionTypes.RESULTS_SET:
            return {
                ...state,
                result: {
                    count: action.payload.count,
                    url: `${action.payload.url}&campaign=SalonDeal&campaign_reference=${state.uniqueId}&campaign-mail-form-open&utm_source=Salon2023&utm_medium=Kiosk&utm_campaign=MyWayMatch`,
                    origUrl: action.payload.url,
                    data: action.payload.data,
                    vehicles: action.payload.vehicles
                }
            };
        case actionTypes.ANSWER_SET:
            let result = []

            if (state.currentQuestion.max_answers > 1) {

                // If 'ik geef er niet om' is selected, all other answers are removed and only 'ik geef er niet om' is selected
                if(initialAnswer['en'] === action.payload.answer.title["en"]){
                    result = [action.payload.answer]
                } else {
                    const initialAnswerInList = find(state.currentAnswers, answer => answer.title["en"] === initialAnswer['en'])
                    if(initialAnswerInList){
                        // If 'ik geef er niet om' was in the list and user choose something else, remove 'ik geef er niet om' from the list
                        result = [...filter(state.currentAnswers, (answer) => answer.title["en"] !== initialAnswer['en']), action.payload.answer]
                    }else{
                        const alreadyInList = find(state.currentAnswers, (answer) => answer.title["en"] === action.payload.answer.title["en"])

                        if (alreadyInList) {
                            // remove from list
                            result = filter(state.currentAnswers, (answer) => answer.title["en"] !== action.payload.answer.title["en"])
                        } else {
                            // add to list
                            if (state.currentAnswers.length < state.currentQuestion.max_answers) {
                                result = [...state.currentAnswers, action.payload.answer]
                            } else {
                                result = state.currentAnswers
                            }
                        }
                    }
                }
            } else {
                result = [action.payload.answer]
            }
            return {
                ...state,
                currentAnswers: result
            };
        case actionTypes.FILTERS_UPDATE:
            const groupedFilters = groupMultipleAnswersAsFilters(state.currentAnswers, state.filters)
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...groupedFilters,
                },
                filtersLastUpdated: Date.now(),
                history: [...state.history, {currentQuestion: state.currentQuestion, currentAnswers: state.currentAnswers, filters: state.filters, result: state.result}],
                allAnswers: [...state.allAnswers, {
                    'number': state.currentQuestion.number,
                    'type': state.currentQuestion.type,
                    question: state.currentQuestion.title,
                    'answers': map(state.currentAnswers, (a) => ({"title": a.title, "filters": a.filters}))
                }]
            };

        case actionTypes.NEXT_QUESTION:
            const history = []
            let currentQuestion = undefined;
            let completed = false
            let loading = false

            if (state.currentQuestion.number === state.questions.length || state.result.count <= 10) {
                currentQuestion = undefined
                completed = true
                loading = true
            } else {
                currentQuestion = resolveQuestion(state, state.currentQuestion.number + 1)
                completed = (currentQuestion) ? false : true

            }

            return {
                ...state,
                currentQuestion: currentQuestion,
                currentAnswers: [],
                completed: completed,
                loading: loading
            };

        case actionTypes.PREVIOUS_QUESTION:
            const prevStateFromHistory = state.history.length > 0 ? state.history[state.history.length - 1] : undefined

            if (prevStateFromHistory) {
                return {
                    ...state,
                    ...prevStateFromHistory,
                    history: without(state.history, state.history[state.history.length - 1]),
                    allAnswers: without(state.allAnswers, state.allAnswers[state.allAnswers.length - 1]),
                }
            } else {
                return state
            }
        case actionTypes.FETCHING_SET:
            return {
                ...state,
                fetching: action.payload.fetching
            }
        case actionTypes.SHORT_URL_SET:
            return {
                ...state,
                loading: false,
                result: {
                    ...state.result,
                    url_short: action.payload.url,
                }
            }
        case actionTypes.START_SCREEN_VISIBLE_SET:
            return {
                ...state,
                startScreenVisible: action.payload.visible
            }
        default:
            throw new Error();
    }
};
