import React, {useContext, useEffect, useRef, useState} from 'react';
import {map, times} from 'lodash'
import { useTranslation } from "react-i18next";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useParams} from 'react-router-dom';
import {DataContext} from "../../../../index";
import VehicleSummary from "./VehicleSummary";

import { Player } from 'video-react';


const VEHICLE_QUERY = `query VehicleSearch($reference: String) {
    vehicleSearch(reference: $reference) {
        edges {
            node {
                url
                reference
                make
                model
                version
                price
                fuel
                firstRegistrationYear
                firstRegistrationMonth
                gearbox
                mileage
                co2
                dealer {
                    legalName
                    name
                    address
                    postal
                    city
                    tel
                    contactUrl
                }
      
                salonDeal {
                    key
                    name
                }

                equipmentGroups {
                    name
                    key
                    equipments {
                        key
                        name
                        priority
                    }
                }

                videoUrl
                images {
                    key
                    url
                    priority
                },
                images360SpinnerUrl
            }
        }
    }
}`;


const VehicleDetail = (props) => {
    const {reference} = useParams();
    const {state, dispatch} = useContext(DataContext);
    const [vehicle, setVehicle] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(undefined)
    const isInitialMount = useRef(true);

    const [videoFullScreen, setVideoFullScreen] = useState(false);

    const { t } = useTranslation();

    const urlExists = (url) =>{
        var http = new XMLHttpRequest();
        try {
            http.open('HEAD', url, false);
            http.send();
            return http.status!=404 && http.status!=500;
        } catch(error){
            console.log('urlExists error', error);
            return false;
        }
        
    }

    useEffect(() => {
        // ugly work around, but it works ...
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            fetchVehicle(reference)
        }
    }, [state.language, state.promo.filters]);

    const fetchVehicle = (reference) => {
        setLoading(true
        )
        fetch("/graphql", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": state.language
            },
            body: JSON.stringify({
                query: VEHICLE_QUERY,
                variables: {reference: reference}
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                const result = res.data.vehicleSearch.edges;
                if(result.length === 1 ){
                    setVehicle(result[0].node)
                }
                setLoading(false);
            })
            .catch((error) => {
                setError("ERROR");
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchVehicle(reference);
    }, [reference]);

    const handleVideoFullScreen = () => {
        //console.log(videoFullScreen)
        setVideoFullScreen(!videoFullScreen);
    }

    useEffect(() => {
      const videoEl = document.getElementById('vehicleVideo');
      //console.log(videoEl)
      if(vehicle && videoEl){videoEl.currentTime = 19;}
    }, [vehicle])
    


    if (loading) {
        return (<React.Fragment>
            <div
                className="grid grid-rows-2 grid-flow-col"
                style={{gap: '1.6vw'}}
            >
                {times(6, () => {return(<div className='bg-gray-lighter animate-pulse rounded-4' style={{minWidth: '29.218vw', minHeight: '40vh'}}></div>)})}
            </div>
        </React.Fragment>)
    }

    return (
        <React.Fragment>
            <Scrollbars
                //ref={scrollRef}
                autoHeight={true}
                autoHeightMax={1080}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
            >
                <React.Fragment>
                    {!vehicle && <div>{t('Vehicle not found...')}</div>}

                    {vehicle &&
                        <React.Fragment>
                            <div
                                className="grid grid-rows-2 grid-flow-col"
                                style={{gap: '1.6vw'}}
                            >
                                {/* {urlExists(vehicle?.videoUrl) && <div className='' style={{minWidth: '29.218vw'}}> */}
                                {vehicle?.videoUrl && <div className={`transition-all ${videoFullScreen ? 'fixed top-0 left-0 z-50 w-full h-screen flex items-center justify-center' : ''}`} style={{minWidth: '29.218vw', background: videoFullScreen ? 'rgba(0,0,0,.7)' : 'none'}}
                                    onClick={(e)=>{
                                        e.stopPropagation();
                                        //console.log('clicked2');
                                        handleVideoFullScreen();
                                    }}
                                >
                                    <div className={`relative transition-all ${videoFullScreen ? '' : 'w-full mb-4'}`}
                                        style={{aspectRatio: videoFullScreen ? 'auto' : '4/3', width: videoFullScreen ? '1024px' : '100%', height: videoFullScreen ? '768px' : '100%'}}
                                        >
                                        <div
                                            id='videoBox'
                                            className="w-full absolute top-0 left-0 object-cover object-center overflow-hidden"
                                            style={{aspectRatio: "4/3", zIndex: 10}}
                                        >
                                            {/* <Player
                                                fluid={true}
                                                autoPlay={true}
                                                muted={true}
                                                loop={true}
                                                startTime={19}
                                                aspectRatio='4:3'
                                                controls={false}
                                                //src={vehicle.videoUrl}
                                                src='https://myway-nl.cust.nl.phyron.com/repo/a081c143-6446-41ba-bb74-23815694fdc2/1024x768.mp4'
                                                className='!pt-0'
                                                preload='auto'
                                            /> */}
                                            <video id='vehicleVideo' width="100%" muted="true" autoplay="true" loop="true" webkitplaysinline="true" playsinline="true" style={{width:'100%'}}>
                                                {/* <source src='https://myway-nl.cust.nl.phyron.com/repo/362b5a31-74d3-4d03-88fb-5afab10d41fb/1024x768.mp4' type="video/mp4" /> */}
                                                <source src={vehicle.videoUrl} type="video/mp4" />
                                            </video>
                                        </div>
                                        <div
                                            className={`w-full bg-gray-lighter animate-pulse rounded-4 absolute top-0 left-0 flex items-center justify-center ${videoFullScreen ? 'hidden' : ''}`}
                                            style={{aspectRatio: "4/3", zIndex: 0}}
                                        >
                                            <div class="lds-ellipsis">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                {/* <div className='' style={{minWidth: '29.218vw'}}>
                                    <div className="w-full relative mb-4"
                                        style={{aspectRatio: "4/3"}}
                                        >
                                        <div
                                            className="w-full absolute top-0 left-0 object-cover object-center overflow-hidden"
                                            style={{aspectRatio: "4/3", zIndex: 10}}
                                        >
                                            <video width="100%" muted="true" autoplay="true" loop="" webkitplaysinline="true" playsinline="true" style={{width:'100%'}}>
                                                <source src='https://myway-nl.cust.nl.phyron.com/repo/362b5a31-74d3-4d03-88fb-5afab10d41fb/1024x768.mp4' type="video/mp4" />
                                            </video>
                                        </div>
                                        <div
                                            className="w-full bg-gray-lighter animate-pulse rounded-4 absolute top-0 left-0"
                                            style={{aspectRatio: "4/3", zIndex: 0}}
                                        ></div>
                                    </div>
                                </div> */}

                                {map(vehicle.images, (image) => {
                                    return (
                                        <div className='' style={{minWidth: '29.218vw'}}>
                                            <div className="w-full relative mb-4" style={{aspectRatio: "4/3"}}>
                                                <div
                                                    className="w-full absolute top-0 left-0 object-cover object-center overflow-hidden"
                                                    style={{aspectRatio: "4/3", zIndex: 10}}
                                                >
                                                    <img src={image.url} />
                                                </div>
                                                <div
                                                    className="w-full bg-gray-lighter animate-pulse rounded-4 absolute top-0 left-0"
                                                    style={{aspectRatio: "4/3", zIndex: 0}}
                                                ></div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {/* Added 2 extra to make last images visible*/}
                                <div className='' style={{minWidth: '29.218vw'}}></div>
                                <div className='' style={{minWidth: '29.218vw'}}></div>
                            </div>
                            {/* {videoFullScreen && <div className='fixed top-0 left-0 bottom-0 right-0 z-50'>
                                <div id='videoBoxFullScreen'>
                                    {urlExists(vehicle?.videoUrl) && <div className=''>
                                        <div className="w-full relative"
                                            style={{aspectRatio: "4/3"}}
                                            >
                                            <div
                                                // className="w-full absolute top-0 left-0 object-cover object-center overflow-hidden"
                                                className="w-full h-screen relative flex justify-center items-center"
                                                style={{aspectRatio: "4/3", zIndex: 10}}
                                                onClick={()=>{
                                                    //console.log('clicked2');
                                                    handleVideoFullScreen();
                                                }}
                                            >
                                                <Player
                                                    fluid={true}
                                                    autoPlay={true}
                                                    muted={true}
                                                    loop={true}
                                                    aspectRatio='4:3'
                                                    src={vehicle.videoUrl}
                                                    //src='https://myway-nl.cust.nl.phyron.com/repo/a081c143-6446-41ba-bb74-23815694fdc2/1024x768.mp4'
                                                    className='!pt-0'
                                                    preload='auto'
                                                />
                                            </div>
                                            <div
                                                className="w-full bg-gray-darker opacity-95 absolute top-0 left-0"
                                                style={{aspectRatio: "4/3", zIndex: 0}}
                                            >Loading...</div>
                                        </div>
                                    </div>}
                                </div>
                                <div onClick={handleVideoFullScreen} className='absolute top-5 right-5 bg-gray-light text-gray-darker w-10 h-10 rounded-full flex items-center justify-center'><i className='fa fa-times' /></div>    
                            </div>} */}
                        </React.Fragment>
                        }

                </React.Fragment>
            </Scrollbars>
            {vehicle && <VehicleSummary vehicle={vehicle} />}
        </React.Fragment>

    );
};

export default VehicleDetail;
