import {filter, find, flatten, forEach, map, orderBy, uniq} from "lodash";
import {Base64} from 'js-base64';

export const resolveQuestion = (state, number) => {
    const question = find(state.questions, (q) => q.number === number)
    if (number > state.questions.length)
        return

    if (!question.skip(state.filters, state.result)) {
        return question
    } else {
        const nextNumber = number + 1

        if (nextNumber > state.questions.length)
            return

        return resolveQuestion(state, nextNumber)
    }
}


export const groupMultipleAnswersAsFilters = (answers, currentFilters) => {
    let filters = flatten(map(answers, (answer) => answer.filters))
    filters = filter(filters, (filter) => (filter.evaluate && filter.evaluate(currentFilters) || filter.evaluate === undefined))

    const objectKeys = uniq(map(filters, (f) => f.key));
    const result = []

    forEach(objectKeys, (key) => {
        const tmpResult = flatten(
            map(
                filter(filters, (f) => f.key === key),
                (f) => f.value
            )
        );

        if (tmpResult.length > 0) result.push({[key]: tmpResult.join()})
    })

    return Object.assign({}, ...result)
}

export const evaluateAnswers = (question, data, currentFilters, onSkipQuestion) => {
    if (question === undefined) return

    if (question.type === "make") {
        const question_answers = map(question.answers, (answer) => {
            return ({...answer, resultCount: answer.count(data, currentFilters)})

        })

        const make_answers = map(data.makes, (make) => ({
            title: {
                en: make.description,
                nl: make.description,
                fr: make.description,
            },
            filters: [
                {key: "makeModelFamily", value: make.key},
            ],
            resultCount: make.count,
            priority: 1
        }))

        return orderBy([...make_answers, ...question_answers], ['priority'], ['desc'])

    } else {
        const result = map(question.answers, (answer) => {
            return ({...answer, resultCount: answer.count(data, currentFilters)})

        })

        const answers = filter(result, (item) => item.resultCount === undefined || item.resultCount > 0)

        if (answers.length === 1) {
            if (onSkipQuestion) {
                onSkipQuestion()
            }
        } else {
            return answers
        }

    }
}

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}

export const generateTemptTokenWithTime = () => {
    const id = "ec993929-25fc-4941-9335-74320a2ffb17"
    const number = getRndInteger(150000, 200000)
    return Base64.encode(`BMS|${id}|${number}`)
}
