import { actionTypes } from './reducer';

export const initializeHandler = () => ({
    type: actionTypes.INITIALIZE,
    payload: {
    }
});

export const resultsSetHandler = (count, url, data, vehicles ) => ({
    type: actionTypes.RESULTS_SET,
    payload: {
        count,
        url,
        data,
        vehicles
    }
});


export const answerSetHandler = (question, answer) => ({
    type: actionTypes.ANSWER_SET,
    payload: {
        question,
        answer
    }
});

export const filtersUpdateHandler = () => ({
    type: actionTypes.FILTERS_UPDATE,
    payload: {

    }
});


export const nextQuestionHandler = () => ({
    type: actionTypes.NEXT_QUESTION,
});

export const previousQuestionHandler = () => ({
    type: actionTypes.PREVIOUS_QUESTION,
});

export const clearHandler = () => ({
    type: actionTypes.CLEAR,
});

export const fetchingSetHandler = (fetching) => ({
    type: actionTypes.FETCHING_SET,
    payload: {
        fetching
    }
});

export const shortUrlSetHandler = (url) => ({
    type: actionTypes.SHORT_URL_SET,
    payload: {
        url
    }
});

export const startScreenVisibleSetHandler = (visible) => ({
    type: actionTypes.START_SCREEN_VISIBLE_SET,
    payload: {
        visible
    }
});

