import React, {useContext} from "react";
import {LANGUAGES} from "../../settings";
import {DataContext} from "../../index";
import {resolveLanguageToSwitchCode} from "../../utils";
import {languageSetHandler} from "../../state/actions";


export default function LanguagePicker() {
  const {state, dispatch} = useContext(DataContext);

  const languageToSwitchTo = resolveLanguageToSwitchCode(state.language)

  return (
    <div class="absolute m-4 bottom-0 right-0">
      <div
        onClick={() => {
          dispatch(languageSetHandler(languageToSwitchTo));
        }}
        class=" bg-green w-32 py-1 text-center rounded-full text-white"
      >
        {LANGUAGES[languageToSwitchTo]}
      </div>
    </div>
  );
}
