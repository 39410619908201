import React, {useContext, useState} from "react";
import {DataContext} from "../../../index";
import {languageSetHandler} from "../../../state/actions";
import {LANGUAGES} from "../../../settings";
import {resolveLanguageToSwitchCode} from "../../../utils";

export default function LanguagePicker() {
  const {state, dispatch} = useContext(DataContext);

  const languageToSwitchTo = resolveLanguageToSwitchCode(state.language)

  return (
    <React.Fragment>
      <div class="space-x-1 text-center flex flex-row">
          <div
            onClick={() => {
              dispatch(languageSetHandler(languageToSwitchTo))
            }}
            class="btn btn-pill text-base font-normal w-32 "
          >
            {LANGUAGES[languageToSwitchTo]}
          </div>
      </div>
    </React.Fragment>
  );
}
