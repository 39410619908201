import React, {useState, useContext} from 'react';
import {find, includes} from "lodash";
import logoSalonDeals from "../../../../assets/MyWay-SalonDeals-2023.png";
import fuelCard from '../../../../assets/fuelcard.svg';
import warranty from '../../../../assets/icon-warranty.svg';
import {QRCodeSVG} from "qrcode.react";
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../../../utils";
import VehicleSideBar from "./VehicleSideBar";
import {DataContext} from "../../../../index";

const VehicleSummary = (props) => {
    const {state} = useContext(DataContext);
    const {vehicle} = props;

    const {t} = useTranslation();

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const handleSidebarOpen = () => {
        setSidebarOpen(true);
    };

    const handleSidebarClose = () => {
        setSidebarOpen(false);
    };

    const salon_labels = [
        {key: "salon_deals_fuel_or_electric_750", type: "fuel", label: t("+1 fuel card of "), labelBold: t("€ 750")},
        {key: "salon_deals_fuel_or_electric_500", type: "fuel", label: t("+1 fuel card of "), labelBold: t("€ 500")},
        {key: "salon_deals_fuel_or_electric_250", type: "fuel", label: t("+1 fuel card of "), labelBold: t("€ 250")},
        {key: "salon_deals_warranty_+4y", type: "warranty", label: t(" of warranty"), labelBold: t("+4 years")},
        {key: "salon_deals_warranty_+3y", type: "warranty", label: t(" of warranty"), labelBold: t("+3 years")},
        {key: "salon_deals_warranty_+2y", type: "warranty", label: t(" of warranty"), labelBold: t("+2 years")},
        {key: "salon_deals_warranty_+1y", type: "warranty", label: t(" of warranty"), labelBold: t("+1 years")},
    ];

    const print_label = (label_key) => {
        if (!label_key || label_key === undefined)
            return

        const salon_promo = find(salon_labels, (label) => label.key === label_key);
        if (salon_promo.type === 'fuel') {

            return (
                <div className="flex whitespace-nowrap font-semibold text-white mb-0.5">
                    <span className='text-lg mr-1'>{salon_promo.label}</span> <strong className='text-xl font-bold'>{salon_promo.labelBold}<sup>*</sup></strong>
                </div>
            );

        } else {

            return (
                <div className="flex whitespace-nowrap font-semibold text-white mb-0.5">
                    <strong className='text-xl font-bold mr-1'>{salon_promo.labelBold}</strong> <span className='text-lg'>{salon_promo.label}<sup>*</sup></span>
                </div>
            );

        }

    };

    console.log(`${vehicle.url}&contact-form-open&campaign=SalonDeal&utm_source=Salon2023&utm_medium=Kiosk&utm_campaign=Salondeals`)

    return (
      <React.Fragment>
        <div
          className="absolute bottom-0 bg-white rounded z-10"
          style={{
            right: "3.125vw",
            width: "26.667vw",
            boxShadow: "0px 0px 24px #0000001A",
          }}
        >
          <div className="px-14 pt-14 mb-12 border-b border-gray-light">
            <div className="text-2xl font-semibold mb-2">
              {vehicle.make} {vehicle.model}
            </div>
            <div className="text-lg font-regular mb-6">{vehicle.version}</div>
            <div className="font-semibold text-xl flex flex-row items-start mb-6">
              <span className="text-sm mt-0.5 mr-1">€</span>{" "}
              <span className="text-3xl mr-1">
                {formatNumber(vehicle.price)}
              </span>{" "}
              <span className="text-sm mt-0.5">{t("BTWi")}</span>
            </div>
            {/* <div className="myway-mesh p-2 mb-3">
                    <div className="bg-white p-3 flex flex-col">
                        {print_label(vehicle.salonDeal?.key)}
                        <div className='flex flex-row'>
                            <div className='flex-grow text-base font-normal'>{t('when purchasing this car')}</div>
                            <div className="ml-1 w-24 object-contain">
                                <img
                                    className="cursor-hand"
                                    src={logoSalonDeals}
                                    alt="Salon Deals - MyWay - 2023"
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
            {vehicle?.salonDeal !== null && (
              <React.Fragment>
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <div className="w-full flex flex-row flex-nowrap items-center justify-center h-18 mb-0">
                    <div
                      className="relative z-10 h-20 w-20 flex items-center justify-center bg-white rounded-full px-6"
                      style={{ boxShadow: "-5px -3px 16px #00000029" }}
                    >
                      <div
                        className={`object-fit text-blue ${
                          includes(vehicle?.salonDeal?.key, "fuel")
                            ? "w-8"
                            : "w-6"
                        }`}
                      >
                        <img
                          className="text-blue"
                          src={
                            includes(vehicle?.salonDeal?.key, "fuel")
                              ? fuelCard
                              : warranty
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="flex items-center flex-grow -ml-10 pl-14 h-14"
                      style={{
                        background:
                          "transparent linear-gradient(270deg, #2D4B8C 0%, #20A1DB 100%) 0% 0% no-repeat padding-box",
                        borderRadius: 60,
                      }}
                    >
                      {print_label(vehicle.salonDeal?.key)}
                      {/* <div className='text-white font-semibold text-15 leading-2 whitespace-nowrap'>+ 1 carte carburant de <span className='font-bold text-21'>250 €</span><sup className='ml-1'>*</sup></div> */}
                    </div>
                  </div>
                  <div className="w-full flex justify-end -mt-1 pr-4">
                    {/* <div className='font-regular text-13 leading-18'><sup className='mr-1'>*</sup>{t('Info and conditions on myway.be/nl/salondeals')}</div> */}
                    <div className="text-xs font-regular text-gray-dark mb-12">
                      <sup className="mr-1">*</sup>
                      {t("Info and conditions on") +
                        " www.myway.be/" +
                        state.language +
                        "/salondeals"}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
            {/* <div className='text-xs font-regular text-gray-dark mb-12'>
                    <sup className='mr-1'>*</sup>{t('Info and conditions on') + ' www.myway.be/' + state.language + '/salondeals'}
                </div> */}
            <div
              className="pb-12 mb-12 border-b border-gray-light flex flex-row"
              onClick={handleSidebarOpen}
            >
              <div className="flex-grow text-lg font-semibold">
                {t("Specifications")}
              </div>
              <i className="fa fa-chevron-down text-lg font-regular"></i>
            </div>
            {vehicle.equipmentGroups && (
              <div className="pb-12 flex flex-row" onClick={handleSidebarOpen}>
                <div className="flex-grow text-lg font-semibold">
                  {t("Options and equipment")}
                </div>
                <i className="fa fa-chevron-down text-lg font-regular"></i>
              </div>
            )}
          </div>

          <div className="pb-14 px-14">
            <div className="text-xl font-semibold mb-7">
              {t("See yourself behind the wheel? We too!")}
            </div>
            <div className="flex flex-row items-center">
              <div className="mr-6">
                <QRCodeSVG
                  value={`${vehicle.url}&contact-form-open&campaign=SalonDeal&utm_source=Salon2023&utm_medium=Kiosk&utm_campaign=Salondeals`}
                  fgColor={"#183C74"}
                  bgColor={"transparent"}
                  style={{ height: "100%", width: "auto" }}
                />
              </div>
              <div className="text-base font-regular">
                {t("PromoDetailQRScanText", {
                  dealerName: vehicle.dealer.legalName,
                })}
              </div>
            </div>
          </div>
        </div>
        <VehicleSideBar
          isOpen={sidebarOpen}
          close={handleSidebarClose}
          vehicle={vehicle}
        />
      </React.Fragment>
    );
}

export default VehicleSummary
