import React, {useEffect} from 'react';
import {QRCodeSVG} from "qrcode.react";
import {map, sample, filter, orderBy} from 'lodash';
import {useTranslation} from 'react-i18next';


const CompletedScreen = ({result}) => {
    const {t} = useTranslation();

    // Effect which check if vehicles container is wider than screen and if it is, it scrolls left for a half of the first card
    useEffect(() => {
        const screenWidth = window ? window.innerWidth : null;
        const vehiclesContainer = document.getElementById('vehicles-container');
        const vehiclesContainerWidth = vehiclesContainer ? vehiclesContainer.scrollWidth : null;

        if(screenWidth !== null && vehiclesContainerWidth !== null){
            if(vehiclesContainerWidth > screenWidth){
                vehiclesContainer.scrollLeft = 220
            }else{
                vehiclesContainer.classList.add('items-center', 'justify-center')
            }
        }
    }, [])

    return (
        <div className="w-full h-screen bg-gray-darker py-[104px] text-white">
            <div className="flex flex-col items-center justify-center text-white w-full h-full">
                <div className="flex items-center justify-center mt-16 font-semibold text-[80px] text-transparent bg-clip-text myway-mesh-vertical ">
                    {t('It is a My Way Match')}
                </div>

                <div className="mt-7 text-[21px] text-white text-center mb-14 font-light">
                    <div>{t('Discover our selection of cars that perfectly meet your needs.')}</div>
                    <div>{t('Because at My Way we are always ready to help you.')}</div>
                </div>

                <div id="vehicles-container" className="flex mb-[88px] pl-10 w-full overflow-x-scroll no-scrollbar h-[387px] min-h-[387px]">
                    {map(orderBy(result.data.makes, ['priority'], ['desc']), (make) => {
                        return map(make.models, (model) => {
                            const random_vehicle = sample(filter(result.vehicles, (v) => v.makeKey === make.key && v.modelFamilyKey == model.key))
                            return (<div className="bg-white rounded-[16px] min-w-[360px] w-[360px] h-[387px] min-h-[387px] mr-10"
                                         style={{boxShadow: '0px 8px 24px #00000029'}}>

                                <div className="h-[240px] mb-4">
                                    <img src={random_vehicle?.noBackgroundImageUrl}
                                         alt='Vehicle image'
                                         className="rounded-t-[16px]"
                                         // className="object-cover object-center w-full h-full rounded-t-[16px]"
                                         style={{padding: 25}}
                                    />
                                </div>

                                <div className="flex flex-col items-center mb-10">
                                    <div className="text-darkText text-[18px] font-semibold mb-4">{make.description} {model.description}</div>
                                    <div className="bg-gray-lighter rounded-[100px] px-8 py-3 text-darkText w-fit">
                                        {model.count} {' '} {model.count > 1 ? t('offers') : t('offer')}
                                    </div>
                                </div>

                            </div>)
                        })
                    })}

                </div>

                <div className="flex items-center justify-center">
                    <div className="w-[136px] h-[136px] mr-14">
                        {result.url_short &&
                            <QRCodeSVG
                                value={result.url_short}
                                fgColor={"#ffffff"}
                                bgColor={"transparent"}
                                style={{height: "100%", width: "auto"}}
                            />
                        }
                    </div>


                    <div className="flex flex-col">
                        <div className="flex items-center text-green">
                            <i className="fa fa-mobile mr-4 text-[29px]"></i>
                            {t('View all your matches on MyWay.be')}
                        </div>

                        <div className="mt-4 text-base text-white max-w-[522px]">
                            <div>{t('Scan the QR code with your smartphone to view all results. Or send them to your email address.')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompletedScreen;
