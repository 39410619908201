import {actionTypes} from "./reducer";

export const languageSetHandler = (language) => ({
    type: actionTypes.LANGUAGE_SET,
    payload: {
        language
    }
});

export const resetALlHandler = () => ({
    type: actionTypes.RESET_ALL,
});
