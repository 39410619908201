import React, {useReducer} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Root from './routes/root'
import Wall from './routes/wall'
import Promo from './routes/promo'
import PromoDetail from './routes/promo-detail'
import {GraphQLClient, ClientContext} from 'graphql-hooks'
import "./i18n";

import {
    createBrowserRouter,
    RouterProvider,
    Route,
} from "react-router-dom";

import Choose from "./routes/choose";
import {rootReducer, initialState} from "./state/reducer";
import PromoList from "./routes/promo-list";



const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>
    },
    {
        path: "/wall",
        element: <Wall/>
    },
    {
        path: "/promo",
        element: <PromoList/>
    },
    {
        path: "/promo/list",
        element: <PromoList/>
    },
    {
        path: "/promo/detail/:reference",
        element: <PromoDetail/>
    },
    {
        path: "/choose",
        element: <Choose/>
    },
]);


const client = new GraphQLClient({
    url: '/graphql'
})

export const DataContext = React.createContext(initialState);

const App = () => {
    const [state, dispatch] = useReducer(rootReducer, initialState);

    return (
        <ClientContext.Provider value={client}>
            <DataContext.Provider  value={{ dispatch: dispatch, state: state }}>
                <RouterProvider router={router} />
            </DataContext.Provider>
        </ClientContext.Provider>
    );
};

export default App;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
